import { getBackendApiUrl, getEasyPlvApiToken, getAuthToken, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";
import { setUpdatedCartItemsAndCount } from "./cart";
import { Base64 } from "js-base64";
import { base64url_encode } from "../helpers/helpers";

export const productByEan = async (ean,page=1) => {

		const res = await fetch(getBackendApiUrl(`product_local/byStoreAndFilters?options[page]=${page}&filters[ean]=${ean}`), {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
					"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				},
			});
			checkApiResponse(res);
			const data = await res.json();
		//   console.log(data);
			return data;

}

export const productLocalByCommercialName = async (name, page=1) => {
	const res = await fetch(getBackendApiUrl(`product_local/byStoreAndFilters?options[page]=${page}&filters[commercialName]=${name}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
}

export const getProductById = async (id) => {
	const locale = localStorage.getItem('lang');
	const response = await fetch(getBackendApiUrl(`products/${id}?locale=${locale}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		}
	});

	checkApiResponse(response);
	const productData = response.json();
	return productData;
}


export const getLegalMentions = async (productId) => {

	const res = await fetch(getBackendApiUrl(`legalNotices/byStoreAndProduct/${productId}`), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;

}

export const saveProduct = async (productDetails) => {
	try {
		const saveProductPromise = await fetch(getBackendApiUrl('products/saveFrontProduct'), {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(productDetails)
		});
		checkApiResponse(saveProductPromise);
		if (saveProductPromise.status !== 200) return false;
		const response = await saveProductPromise.json();
		if ('updatedCartData' in response) {
			if (response.updatedCartData) {
				setUpdatedCartItemsAndCount(response.updatedCartData);
			}
		} 
		return response;
	} catch (e) {console.log(e)}

}

export const getPartialPriceHtml = async (dataForPricePartial) => {
	try {
		const priceValue = dataForPricePartial['priceValue'];
		const discountId = dataForPricePartial['discountId'];
		const companyId = dataForPricePartial['companyId'];
		const productId = dataForPricePartial['productId'];
		const discountValuesJson = dataForPricePartial['discountValuesJson'];
		const priceImage = dataForPricePartial['priceImage'] ? dataForPricePartial['priceImage'] : null;
		const moduleType = dataForPricePartial?.['moduleType'] ?? 'plv';
		const otherDataJson = {
			priceImage: priceImage
		};

		const base64DiscountValuesJson = base64url_encode(Base64.encode(discountValuesJson));
		const base64OtherDataJson = base64url_encode(Base64.encode(JSON.stringify(otherDataJson)));

		const url = getBackendApiUrl(`poster/partials/price/${priceValue}/${productId}/${discountId}/${base64DiscountValuesJson}/${companyId}?otherDataJson=${base64OtherDataJson}`);
		url.searchParams.append('moduleType', moduleType);
		const partialPriceHtmlPromise = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": 'application/json'
			}
		});

		checkApiResponse(partialPriceHtmlPromise);
		if (partialPriceHtmlPromise.status === 500) return;
		return await partialPriceHtmlPromise.json();

	} catch(e) {console.log(e)}
}

export const getPartialTemplateExtension = async (dataForTemplateExtensionPartial) => {
	try {
		const templateExtensionId = dataForTemplateExtensionPartial.id;
		const templateExtensionValuesJson = dataForTemplateExtensionPartial.valuesJson;
		const companyId = dataForTemplateExtensionPartial.companyId;
		const productId = dataForTemplateExtensionPartial.productId;

		const base64TemplateExtensionValuesJson = base64url_encode(Base64.encode(JSON.stringify(templateExtensionValuesJson)));

		const url = getBackendApiUrl(`poster/partials/templateExtension/${templateExtensionId}/${base64TemplateExtensionValuesJson}/${companyId}/${productId}`);
		const partialTemplateExtensionPromise = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": 'application/json'
			}
		});

		checkApiResponse(partialTemplateExtensionPromise);
		if (partialTemplateExtensionPromise.status === 500) return;
		return await partialTemplateExtensionPromise.json();

	} catch (error) {
		console.error(error);
	}
}

export const getProductsByUser = async (page=1) => {
	const res = await fetch(getBackendApiUrl(`products/byUser?options[itemsPerPage]=20&options[page]=${page}`), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;
}

export const getProductsByUserFiltered = async (page = 1,filter) => {
		const res = await fetch(getBackendApiUrl(`products/byUser?options[itemsPerPage]=20&options[page]=${page}${filter}`), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;
}

export const getFormatsByCompany = async () => {
	const res = await fetch(getBackendApiUrl(`formats/byCompany`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
}

export const deleteProductById = async (productId) => {
	const res = await fetch(getBackendApiUrl(`products/${productId}/deleteUserProduct`), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			"Content-Type": "application/json",
		},
		body: JSON.stringify([])
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
}

export const getDefaultPosterData = () => {
	const defaultData = {
		productId: 0,
		format: {
			value: 5,
			label: 'A4',
			size: {
				width: 210,
				height: 297,
				unit: "mm"
			}
		},
		orientation: {
			value: 'portrait',
			label: 'portrait'
		},
		onSaveAction: 'DUPLICATE',
		showImageProduct: true
	}

	return defaultData;
}


////////////////// GET CHARTES GRAPHIC WITH FILTER AND OPTIONS /////////////////////////////
export const getTemplateGroups = async (templateGroupBodyData = null) => {
	let queryParamStringOptions = '';
	let queryParamStringFilters = '';
	if (templateGroupBodyData)
	{
		const options = templateGroupBodyData.options;
		const filters = templateGroupBodyData.filters;

		queryParamStringOptions = Object.entries(options).map(([key, option]) => {
			return getOptionsQueryParam(key, option);
		}).join('&');

		queryParamStringFilters = Object.entries(filters).map(([key, filter]) => {
			return getFiltersQueryParam(key, filter);
		}).join('&')
	}

	const response = await fetch(getBackendApiUrl(`templateGroups/byStoreAndFilters?${queryParamStringOptions}&${queryParamStringFilters}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	});

	checkApiResponse(response);

	const data = await response.json();
	return data;
}

export const getDefaultTemplateGroupBodyData = () => {
	return {
		options: {
			page: 1,
			itemsPerPage: 10,
			sort: [
				{
					direction: 'ASC',
					orderBy: 'name'
				}
			]
		},
		filters: {
			name: ''
		}	
	};
}

////////////////////////////////////////////////////////////////////////////

////////////////// GET DISCOUNT(PRICE MECHANIC) WITH FILTER AND OPTIONS /////////////////////////////
export const getDiscounts = async (discountBodyData = null) => {
	let queryParamStringOptions = '';
	let queryParamStringFilters = '';
	if (discountBodyData)
	{
		const options = discountBodyData.options;
		const filters = discountBodyData.filters;
		filters['module'] = "plv";

		queryParamStringOptions = Object.entries(options).map(([key, option]) => {
			return getOptionsQueryParam(key, option);
		}).join('&');
        
		queryParamStringFilters = Object.entries(filters).map(([key, filter]) => {
			return getFiltersQueryParam(key, filter);
		}).join('&')
	}

	const response = await fetch(getBackendApiUrl(`discounts/byStoreAndFilters?${queryParamStringOptions}&${queryParamStringFilters}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	});

	checkApiResponse(response);

	const data = await response.json();
	return data;
}

export const getDefaultDiscountBodyData = () => {
	return {
		options: {
			page: 1,
			itemsPerPage: 10,
			sort: [
				{
					direction: 'ASC',
					orderBy: 'name'
				}
			]
		},
		filters: {
			name: ''
		}	
	};
}
////////////////////////////////////////////////////////////////////////////

const getOptionsQueryParam = (key, value) => {
	let queryParam = '';
	switch (key) {
		case 'page':
			queryParam = `options[page]=${value}`
			break;
		case 'itemsPerPage':
			queryParam = `options[itemsPerPage]=${value}`
			break;
		case 'sort':
			value.forEach((sortObj, index) => {
				if (index > 0) queryParam += '&';
				queryParam += `options[sort][${sortObj.orderBy}]=${sortObj.direction}`;
			});
			break;
		default:
			break;
	}
	return queryParam;
}


const getFiltersQueryParam = (key, value) => {
	let queryParam = null;
	switch (key) {
		case 'name':
			queryParam = `filters[name]=${value}`
			break;
        case 'module':
            queryParam = `filters[module]=${value}`
            break;
		default:
			break;
	}

	return queryParam;
}
///////////////////////////////////////////////////////////////////////////////////////////////


///////////// HANDLER FUNCTIONS FOR PREVIEW LISTS ///////////////////////////////////////
/////////////// HANDLE SEARCH FOR PREVIEW LIST /////////////////
export const handleSearchData = async (searchValue, bodyData, getListData = null, bodyDataUpdator = null) => {
	bodyData.filters.name = searchValue;

	// Update the bodyData state
	bodyDataUpdator(bodyData);

	// console.log("SEARCHING...",bodyData);

	const newListData = await getListData(bodyData);
	return newListData?.items;
}

//////////// HANDLE SORT DATA FOR CHARTES GRAPHIQUES /////////////////////////
export const handleSortData = async (direction, sortingType, bodyData, getListData = null, bodyDataUpdator = null) => {
	let updatedSort = [...bodyData.options.sort];

	const sortIndex = updatedSort.findIndex(sortObj => sortObj.orderBy === sortingType.orderBy);

	if (sortIndex !== -1) {
		// Update existing sort direction
		updatedSort[sortIndex] = { ...updatedSort[sortIndex], direction: direction };
	} else {
		// Add new sort criteria
		updatedSort = [{ direction: direction, orderBy: sortingType.orderBy }];
	}

	bodyData.options.sort = updatedSort;

	// console.log("Sorting...",bodyData);
	// Update the bodyData state
	bodyDataUpdator(bodyData);

	const sortedListData = await getListData(bodyData);
	return sortedListData?.items;
};

//////////// HANDLE FETCH MORE DATA ///////////////////
export const handleFetchMoreData = async (page, bodyData, getListData = null, bodyDataUpdator = null) => {
	// console.log("Handling More data...");
	// console.log(bodyData);

	bodyData.options.page = page;

	// Update the bodyData state
	bodyDataUpdator(bodyData);

	const updatedListData = await getListData(bodyData);
	return updatedListData?.items;
}

// GET DEFAULT DATES OBJECT
export const getDefaultDateObj = (startDate, endDate) => {
	return {
		startDate: startDate ?? new Date(),
		endDate: endDate ?? new Date()
	}
}

export const getUserCategory = async (page=1) => {
	const res = await fetch(getBackendApiUrl(`user_product_category/byFilters?options[itemsPerPage]=25&options[page]=${page}`), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;
}

export const addNewUserCategory = async (newCategory) => {
	const res = await fetch(getBackendApiUrl(`user_product_category/create`), {
			method: "POST",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newCategory)
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;
}


export const deleteUserCategory = async (categoryId) => {
	let data;
	const res = await fetch(getBackendApiUrl(`user_product_category/${categoryId}/delete`), {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": "application/json",
			},
			
		});
		checkApiResponse(res);
		// console.log(res.status);
		if(res.status == 204){
			data =  'deleted';
		}
		//const data = await res.json();
		
		return data;
}

export const assignProductToCategory = async (product_id,categoryId) => {
	const res = await fetch(getBackendApiUrl(`products/${product_id}/changeUserProductCategory`), {
			method: "POST",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({"newUserProductCategory":categoryId})
			
		});
		checkApiResponse(res);
		return res;
}

export const editUserCategory = async (categoryId,newCategory) => {
	const res = await fetch(getBackendApiUrl(`user_product_category/${categoryId}/edit`), {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newCategory)
		});
		checkApiResponse(res);
		const data = await res.json();
		return data;
}

export const duplicateProductById = async (productId) => {
	const res = await fetch(getBackendApiUrl(`products/${productId}/duplicate`), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			"Content-Type": "application/json",
		},
		body: JSON.stringify([])
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
}

export const getFormatsData = async () => {
	const res = await fetch(getBackendApiUrl(`/formats/byCompany`), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			"Content-Type": "application/json",
		},
		body: JSON.stringify([])
	});
	checkApiResponse(res);
	const data = await res.json();
	return data;
}

/**
 * Determines whether to show a tag on the Chartes Graphique Preview List.
 * 
 * @param {ListDataItem} item - The data item from the Preview List to be checked.
 * @returns {ShowAndReturnTag} - An object representing the show and return tag, understood by the Preview List.
 */
export const showChartGraphiqueTagForPreviewList = (item) => {
	/**
     * The Object to be returned
     * @type {ShowAndReturnTag}
     */
	const previewListTagObj = {
		showTag: false
	}

	if (item?.isStatic) {
		previewListTagObj.showTag = true;
	}

	return previewListTagObj;
}

/**
 * The function `getBarcode` fetches barcode data by EAN from a backend API using authentication and
 * error handling.
 * @param ean - The `ean` parameter in the `getBarcode` function is the European Article Number (EAN)
 * that is used to uniquely identify a product. It is a barcode standard commonly used for product
 * identification in retail environments.
 * @returns The `getBarcode` function returns a Promise that resolves to the barcode data fetched from
 * the backend API if the response status is 200. If the response status is not 200, it returns `null`.
 * If there is an error during the process, it logs the error to the console and also returns `null`.
 */
export const getBarcode = async (ean, orientation) => {
	try {  
		const authToken = await getAuthTokenWithRefresh();
		const getBarcodePromise = await fetch(getBackendApiUrl(`poster/getBarcodeByEan/${ean}?orientation=${orientation}`), {
			method:  "GET",
			headers: {
				Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": "application/json",
			}
		});
	
		checkApiResponse(getBarcodePromise);
	
		if (getBarcodePromise.status === 200) {
			return await getBarcodePromise.json();
		} else {
			return null;
		}

	} catch (e) {
		console.log(e);
		return null
	}
}

/**
 * The function `translateOrientationOptions` takes an array of orientation options and a translation
 * function `t`, and returns a new array of options with their labels translated using the `t`
 * function.
 * @param orientationOptions - The `orientationOptions` parameter is an array of objects that contain
 * options for orientation. Each object in the array has a `label` property that represents the label
 * for that option.
 * @param t - The `t` parameter is a function that is used for translating text. In this case, it is
 * being used to translate the label of each option in the `orientationOptions` array.
 * @returns The `translateOrientationOptions` function is returning a new array where each object in
 * the `orientationOptions` array has been transformed to include a `label` property with its value
 * translated using the `t` function.
 */
export const translateOrientationOptions = (orientationOptions, t) => {
	if (orientationOptions)
	{
		const translatedOptions =  orientationOptions.map(option => ({
			...option,
			label: capitalize(t(`orientationTitle.${option.value}`)),
		}));
		return translatedOptions
	}
	return null;
}

const capitalize = s => s && String(s[0]).toUpperCase() + String(s).slice(1)

/**
 * Returns the default volume text based on the provided unit value.
 * 
 * @typedef {Object} VolumeUnit
 * @property { string | undefined } value
 * 
 * @typedef {Object} VolumeData
 * @property {string | undefined} value
 * @property {VolumeUnit | undefined} unit
 *
 * @typedef {Object} additionalOptions
 * @property {boolean} conditionForNotSingleValue - True or false provided on when we should display volume
 * text when there is no singular value
 * 
 * @param {VolumeData} volume - The volume object passed to the function
 * @param {additionalOptions} additionalOptions - additionalOptions can be passed as an object
 * @returns {string} - The corresponding volume text in French.
 *
 * @example
 * // Returns "Le KG"
 * getDefaultVolumeTextByVolumeUnit("KG");
 *
 * @example
 * // Returns "L'Unité"
 * getDefaultVolumeTextByVolumeUnit("unknownUnit");
 */
export const getDefaultVolumeTextByVolumeUnit = (volume, additionalOptions = {}) => {
	let volumeText = ""
	const value = volume?.value;
	const unitValue = volume?.unit?.value;
	const conditionIfNotSinlgeValue = additionalOptions?.conditionForNotSingleValue;

	if (value === "1") {
		switch (unitValue) {
			case "KG":
				volumeText = "Le KG";
				break;
			case "L":
				volumeText = "La Litre";
				break;
			case "g":
				volumeText = "Le gramme";
				break;
			default:
				volumeText = "L'Unité";
				break;
		}
	} else {
		if (conditionIfNotSinlgeValue){	
            let space = '';
            if (unitValue && value) space = ' ';
			volumeText = `${value ?? ''}${space}${unitValue ?? ''}`;
		}
	}				

	return volumeText;
}