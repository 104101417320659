import { useEffect, useState, useContext, memo } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import {
  Row,
  Col,
  Form,
} from "react-bootstrap";
import "./Operation.css";
import OperationProductsCard from "./OperationProductsCard";
import { addProductToCart } from "../../services/cart";
import Select from "react-select";
import { highlightProductCards,formatDateRange, splitWordsWithKeys } from "../../services/operation";
import { useAuth } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { ArrowLeft } from "react-bootstrap-icons";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

//Poster services
import { translateOrientationOptions } from "../../services/posters";

// Nw utility Components
import NwCustomCheckbox from "../_utilityComponents/NwFormsUi/CustomCheckbox/NwCustomCheckbox";
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

// Contexts
import { useMainLayoutContext } from "../../hooks/useMainLayoutContext";
import NwPageLoader from "../_utilityComponents/NwPageLoader/NwPageLoader";

const OperationDateComponent = ({formattedDateRange}) => {
	return (
		<div className="operation_date_container text-secondary">
			{formattedDateRange}
		</div>
	);
}

const OperationProducts = memo(() => {
	const [operationId, setId] = useState(useParams().operationId);
	const [companyId, setCompanyId] = useState(null);
	const { setPageTitle, setPageTitleAdditionalContent } = useMainLayoutContext();
	const { logoutUser } = useAuth();
	const [ impressionMultipleWithImage, setImpressionMultipleWithImage] = useState(true);
	const navigate = useNavigate();

	const [posts, setPosts] = useState([]);
	const postsPerPage = 14;
	const [pageCount, setPageCount] = useState(0);
	const [totalItems, setTotalItems] = useState(0);

	const [selectedFormat, setSelectedFormat] = useState(null);
	const [OrientationSelectDataFinal, setOrientationSelectDataFinal] = useState(
		[]
	);

	const apiKey = getEasyPlvApiToken();

	const [newIsLoadingMultiple, setNewIsLoadingMultiple] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const [changePageLoading, setChangePageLoading] = useState(false);
	const [paginateCurrentPage, setCurrentPagePaginate] = useState(0);
	const [isFilteredData, setIsFilteredData] = useState(false);
	const [isChecked, setIsChecked] = useState(true);
	const { t } = useTranslation();

	const [selectedFormatValues, setSelectedFormatValues] = useState();
	const [selectedOrientationValues, setSelectedOrientationValues] = useState();
	const [reduceValue, setReduceValue] = useState(0);

	const [operationName, setOperationName] = useState("");
	const [operationCode, setOperationCode] = useState("");
	const [operationDateStart, setOperationDateStart] = useState("");
	const [operationDateEnd, setOperationDateEnd] = useState("");
	const [operationState, setOperationState] = useState("");
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setIsError] = useState(false);

	const [searchOperationName, setSearchOperationName] = useState("");

	const [filterQueryStr, setFilterQueryStr] = useState();


	const notyf = new Notyf({
		duration: 5000,
			position: {
				x: 'right',
				y: 'top',
			},
		types: [
		{
			type: 'info',
			background: 'green',
			icon: false,
			
		}
		]
	});

	let actualSelectedFormat = null;
	let actualSelectedOrientation = null;
	let selectedWithImage = 0;
	let orientationWarning = t("please_Select_an_orientation")
	let formatWarning = t("please_Select_a_format");

	useEffect(() => {
		const loadProducts = async () => {
			const authToken = await getAuthTokenWithRefresh();
			const response = await fetch(getBackendApiUrl(`operation/products/${operationId}`), {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
					"X-EasyPLV-Api-Token": apiKey,
				},
			})
			
			setIsLoading(false);
			if (!response.ok) {
				setIsError(true);
				throw new Error(`HTTP error! Status: ${response.status}`);
			} else {
				setData(await response.json());
			}
		}

		loadProducts();
	}, [])

	const getOperationMetadata = async (operationId) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
			getBackendApiUrl(`operation/metadata/${operationId}`),
			{
				method: "GET",
				headers: {
				Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": apiKey,
				},
			}
		);
		if (res.status === 401) {
			logoutUser();
			return;
		}
		const data = await res.json();

		if (data.code === 401) {
			logoutUser();
		}
		const orientationJson = data?.formats?.orientation_select;
		if (!orientationJson) {
			logoutUser();
		}

		if (data.company) {
			setCompanyId(data.company.id);
		}

		setPageTitle(data?.operation?.name || "");
		setOperationName(data?.operation?.name || "");
		setOperationCode(data?.operation?.code || "");
		setOperationDateStart(data?.operation?.dateStart || "");
		setOperationDateEnd(data?.operation?.dateEnd || "");
		setOperationState(data?.operation?.state || "");

		localStorage.setItem("orientationSelectData", orientationJson);
		const departmentJSON = JSON.stringify(data.departments_select);
		localStorage.setItem("departmentSelectData", departmentJSON);

		const formatJson = JSON.stringify(data?.formats?.formats_select);
		localStorage.setItem("formatSelectData", formatJson);

		const vitesseCsv = JSON.stringify(data?.vitesseCsv?.vitesse_csv_select);
		if (data?.vitesseCsv?.length === 0)
			localStorage.setItem("vitesseCsvSelectData", JSON.stringify([]));
		else localStorage.setItem("vitesseCsvSelectData", vitesseCsv);
		return data;
	};

	//Total Product Count

	useEffect(() => {

		if (data && !isFilteredData && !reduceValue) {
			setPosts(data.items);
			setTotalItems(data.totalItems);
			setPageCount(Math.ceil(totalItems / postsPerPage));
		} else {
			setPageCount(Math.ceil(totalItems / postsPerPage));
		}

		setPageCount(Math.ceil(totalItems / postsPerPage));
		getOperationMetadata(operationId);


	}, [data,isFilteredData,totalItems,reduceValue]);
	
	
	useEffect(() => {
		if(paginateCurrentPage > 0){
			handlePageClick(paginateCurrentPage);
		}
	  }, [paginateCurrentPage]);
	
	const [formData, setFormData] = useState({
		itemID: "",
		radioValue: "",
		format: "",
		orientation: "",
		customData: "",
	});

	const [customData, setCustomData] = useState({});

	const [endOn, setEndOn] = useState("");
	const [startOn, setStartOn] = useState("");

	const fetchProducts = async (currentPage) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(getBackendApiUrl(`operation/products/${operationId}?page=${currentPage}`),
		{
			method: "GET",
			headers: {
			Authorization: `Bearer ${authToken}`,
			"X-EasyPLV-Api-Token": apiKey,
			},
		}
		);

		const data = await res.json();
		return data;
	};

	const filterProduct = async (filter) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
		getBackendApiUrl(`operation/products/${operationId}?${filter}`),
		{
			method: "GET",
			headers: {
			Authorization: `Bearer ${authToken}`,
			"X-EasyPLV-Api-Token": apiKey,
			},
		}
		);

		const data = await res.json();
		return data;
	};

	const filterProductPaginate = async (currentPage=1,filter) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
		getBackendApiUrl(`operation/products/${operationId}?page=${currentPage}&${filter}`),
			{
				method: "GET",
				headers: {
				Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": apiKey,
				},
			}
		);

		const data = await res.json();
		return data;
	};

	const multiplePrintCall = async (filter) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
		getBackendApiUrl(`cart/multipleprint/${operationId}?${filter}`),
		{
			method: "GET",
			headers: {
			Authorization: `Bearer ${authToken}`,
			"X-EasyPLV-Api-Token": apiKey,
			},
		}
		);

		const data = await res.json();
		return data;
	};

	const handlePageClick = async (data) => {
		let currentPage = data;
		let filteredProds = null;
		setCurrentPagePaginate(data);
		setChangePageLoading(true);
		if(filterQueryStr){

			filteredProds = await filterProductPaginate(currentPage,filterQueryStr);
		}else {
			filteredProds = await fetchProducts(currentPage);
		}
		setChangePageLoading(false);

		setPosts(filteredProds.items);
		setReduceValue(reduceValue + 1);
		window.scrollTo(0, 0);
	};

	let departmentSelectData = [];
	const storedDepartmentData = localStorage.getItem("departmentSelectData");
	if (storedDepartmentData === "undefined") {
	} else {
		departmentSelectData = JSON.parse(storedDepartmentData);
	}

	let formatSelectData = [];
	const storedFormatData = localStorage.getItem("formatSelectData");
	if (storedFormatData === "undefined") {
	} else {
		formatSelectData = JSON.parse(storedFormatData);
	}

	let vitesseCsvSelectData = [];
	const storedVitesseCsvData = localStorage.getItem("vitesseCsvSelectData");
	if (storedVitesseCsvData === "undefined") {
	} else {
		vitesseCsvSelectData = JSON.parse(storedVitesseCsvData);
	}

	let OrientationSelectData = [];

	const handleRadioChange = (isChecked) => {
		
		setIsChecked(isChecked);
		highlightProductCards(posts, isChecked,selectedFormatValues?.value,selectedOrientationValues?.value );
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();

		let queryString = "";

		const searchValue = e.target.search.value;
		const parVitesseValue = e.target.par_vitesse.value;
		const parRayonValue = e.target.par_rayon.value;

		if (searchValue) {
		queryString += `name=${searchValue}`;
		}

		if (parVitesseValue) {
		if (queryString) {
			queryString += `&vitesse=${parVitesseValue}`;
		} else {
			queryString += `vitesse=${parVitesseValue}`;
		}
		}

		if (parRayonValue) {
		if (queryString) {
			queryString += `&rayon=${parRayonValue}`;
		} else {
			queryString += `rayon=${parRayonValue}`;
		}
		}
		setFilterQueryStr(queryString);
		const filteredProducts = await filterProductPaginate(1,queryString);

		setPosts(filteredProducts.items);
		setTotalItems(filteredProducts.totalItems);
		setIsFilteredData(true);
		setCurrentPagePaginate(1);
	};

	const handleMultiplePrint = async (e) => {
		e.preventDefault();

		let queryString = "";

		const VitesseValue = e.target.vitesse.value;
		const RayonValue = e.target.rayon.value;
		const FormatValue = e.target.format.value;
		const OrientationValue = e.target.orientation.value;
		const withImage = impressionMultipleWithImage ? "1" : "0";

		if (VitesseValue) {
		queryString += `vitesse=${VitesseValue}`;
		}

		if (RayonValue) {
		if (queryString) {
			queryString += `&rayon=${RayonValue}`;
		} else {
			queryString += `rayon=${RayonValue}`;
		}
		}

		if (FormatValue) {
		if (queryString) {
			queryString += `&format=${FormatValue}`;
		} else {
			queryString += `format=${FormatValue}`;
		}
		}

		if (OrientationValue) {
		if (queryString) {
			queryString += `&orientation=${OrientationValue}`;
		} else {
			queryString += `orientation=${OrientationValue}`;
		}
		}

		if (withImage) {
		if (queryString) {
			queryString += `&with_image=${withImage}`;
		} else {
			queryString += `with_image=${withImage}`;
		}
		}

		if (FormatValue === "" || FormatValue === "0") {
		Swal.fire(formatWarning, " ", "error");
		return false;
		} else if (OrientationValue === "" || OrientationValue === "0") {
		Swal.fire(orientationWarning, " ", "error");
		return false;
		}

		setNewIsLoadingMultiple(true);
		const multiplePrint = await multiplePrintCall(queryString);
		try {
			setUpdatedCartItemsAndCount(multiplePrint);
			notyf.success(t("cartPage.cartTable.addedToCart")+' ('+multiplePrint?.updatedCartData?.cartItemsCount+')');
		} catch (error) {
			notyf.error("Error");
		}

		setNewIsLoadingMultiple(false);

		setReduceValue(reduceValue + 1);
	};

	const setUpdatedCartItemsAndCount = (updatedCartData) => {
		localStorage.setItem('updatedCartItems', JSON.stringify(updatedCartData.updatedCartData.cartItems));
		localStorage.setItem('updatedCartItemsCount', updatedCartData.updatedCartData.cartItemsCount);
		window.dispatchEvent(new Event('storage'))
	}

	const handleSubmit = (e, itemId) => {
		e.preventDefault();

		const itemData = {
			itemID: itemId,
			radioValue: isChecked ? 1 : 0, //with or without image
			format: selectedFormatValues?.value,
			orientation: selectedOrientationValues?.value,
			customData: customData[itemId] || "",
		};

		if (itemData.format === "") {
			Swal.fire(formatWarning, " ", "error");
			return false;
		} else if (itemData.orientation === "") {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}
        		
        if (selectedFormatValues === "" || selectedFormatValues === undefined) {
            Swal.fire(formatWarning, " ", "error");
            return false;
            }
    
            if (selectedOrientationValues === "" || selectedOrientationValues === undefined) {
            Swal.fire(orientationWarning, " ", "error");
            return false;
        }

        setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: true,
		}));

		addItemToCart(itemData);
		
		setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: false,
		}));
		
		let addToCartButton = document.getElementById("addToCart-"+itemId);
		addToCartButton.style.backgroundColor = "#64CCC5";
		
		let cardElement = document.getElementById(`product-card-`+itemId);
		cardElement.style.backgroundColor = "#A6FF96";

		test12(); 

		highlightProductCards(
			posts,
			actualSelectedFormat,
			selectedOrientationValues?.value
		);

		setReduceValue(reduceValue + 1);
	};

	const handleInputChange = (selectedOption, itemId, other = "") => {
		const name = other?.type;
		const value = selectedOption;
		const updatedFormData = { ...formData };

		if (name === "format") {


			setSelectedFormatValues(value);

			let elements = document.getElementsByClassName("list-product-item-card");
			for (let i = 0; i < elements.length; i++) {
				elements[i].style.backgroundColor = "white";
			}

			highlightProductCards(posts, value?.value);
			const formatId = value?.value;

			let foundData = null;
			const orientationSelectData = JSON.parse(
				localStorage.getItem("orientationSelectData")
			);

			for (const key in orientationSelectData) {
				if (parseInt(key) === parseInt(formatId)) {
				foundData = {
					key: key,
					data: orientationSelectData[key],
				};
				break;
				}
			}

			if (foundData) {
				// set selection select to zero
				setSelectedOrientationValues("");
                const translatedOrientationOptions = translateOrientationOptions(foundData.data, t);
				setOrientationSelectDataFinal(translatedOrientationOptions);

                if (translatedOrientationOptions.length === 1) {
                    setSelectedOrientationValues(translatedOrientationOptions[0]);
                }
			} else {
				console.log(`ID ${formatId} not found in orientationSelectData`);
			}
		} else if (name === "orientation") {
			setSelectedOrientationValues(value);

			let elements = document.getElementsByClassName("list-product-item-card");
			for (let i = 0; i < elements.length; i++) {
				elements[i].style.backgroundColor = "white";
			}
		
			highlightProductCards(
				posts,
				actualSelectedFormat,
				selectedOrientationValues?.value
			);
		} else if (name === "customData") {
			setCustomData({
				...customData,
				[itemId]: value,
			});
		}
	};

	const addItemToCart = async (newItem) => {	
		const addToCart = await addProductToCart(newItem);		
		return addToCart;
	};

	const getFormatData = (selectedOption) => {
		setSelectedFormat(selectedOption);
		const formatId = selectedOption.value;

		let foundData = null;
		const orientationSelectData = JSON.parse(
		localStorage.getItem("orientationSelectData")
		);

		for (const key in orientationSelectData) {
		if (parseInt(key) === formatId) {
			foundData = {
			key: key,
			data: orientationSelectData[key],
			};
			break;
		}
		}

		if (foundData) {
		setOrientationSelectDataFinal(foundData.data);
		} else {
		console.log(`ID ${formatId} not found in orientationSelectData`);
		}
	};

	const redirectToPreviewPage = (poster_id, format, orientation) => {
		if (format === "" || format === undefined) {
            Swal.fire(formatWarning, " ", "error");
            return false;
		}

		if (orientation === "" || orientation === undefined) {
            Swal.fire(orientationWarning, " ", "error");
            return false;
		}

        return true;
	};

	const redirectToEditPage = (endOn,operationId,startOn,product_id,format,orientation) => {
		
		if (format === "" || format === undefined) {
			Swal.fire(formatWarning, " ", "error");
			return false;
		}

		if (orientation === "" || orientation === undefined) {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}

		const url = `/posters/edit?from=products&end_on=${endOn}&operation_id=${operationId}&product_id=${product_id}&start_on=${startOn}${
		format?.value ? `&format=${format?.value}` : ""
		}${orientation?.value ? `&orientation=${orientation?.value}` : ""}${companyId ? `&company_id=${companyId}` : ""}&showImageProduct=${isChecked}&onSave=DUPLICATE`;

		navigate(url);
	};

	const handleProductLocalNameInput = (e) =>{
		setSearchOperationName(e.target.value);
	  }

	  const customStyles = {
		control: (provided, state) => ({
		  ...provided,
		  borderColor: state.isFocused ? '#0D6DFD' : provided.borderColor,
		  borderBottom: state.isFocused ? '2px solid #0D6DFD' : '2px solid var(--nw-primary-color)',
		  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : provided.boxShadow,
		  borderLeft: 'none', // Remove left border
   			 borderTop: 'none',
		}),
		indicatorSeparator: (provided) => ({
		  ...provided,
		  backgroundColor: '#white', // Set the color of the arrow here
		}),
		dropdownIndicator: (provided) => ({
		  ...provided,
		  backgroundColor: '#0D6DFD',
		  color: 'white', // Set the color of the arrow here
		}),
	  };

	const translatedDateRange = () =>{
		const translatedDate = formatDateRange(operationDateStart,operationDateEnd);
		const newTranslatedDate = splitWordsWithKeys(translatedDate, t);
		if (newTranslatedDate.includes("Invalid")) {
			return ""
		}else{
		return newTranslatedDate;
		}
	}

	// Handling with page title additional content
	useEffect(() => {
		setPageTitleAdditionalContent({
			classes: {
				pageTitleWrapper: 'with-operation-date'
			},
			content: <OperationDateComponent formattedDateRange={translatedDateRange()}/>
		})
	}, [operationDateStart, operationDateEnd]);

	
	
	const test12 = () =>{
		notyf.success(t("cartPage.cartTable.addedToCart"));
	}

	if (isLoading) return <NwPageLoader/>;

	if (error) return "An error has occurred: " + error.message;


	return (
		<div className="position-relative">
			{changePageLoading ? (
				<NwPageLoader/>
			) : null}
			<div className="px-5 pt-3">
				<Link
					to={'/operation'}
					className="btn nw-bg-accent-1 text-white nw-responsive-font-size rounded-pill d-flex align-items-center fw-semibold"
					style={{
						width: 'fit-content',
					}}
				>
					<ArrowLeft className="me-1"/>{t("back_to_operations")}
				</Link>
				<NwCommonFilter
					onSubmit={handleFilterSubmit}
					className= "w-100"
					filterContainerProps={{
						className: 'mt-3 mb-3'
					}}
					autoSubmitOnReset
					modalButtonProps={{
						className: 'my-1 my-xxl-4'
					}}
					filterWrapperProps={{
						className: "w-100 justify-content-around",
						style: {
							columnGap: '3rem'
						}
					}}
					filterButtonProps={{
						type: 'submit',
						children: t('validateButtonLabel')
					}}
					resetButtonProps={{
						children: t('resetFilterButton')
					}}
					filterModalProps={{
						headerContent: t('productLocalPage.filterModalHeader')
					}}
					formFields={[
						{
							type: 'input',
							props: {
								id: 'search-by-name-filter',
								name: 'search',
								placeholder: t('searchBarLabel'),
								hideLabel: true
							}
						},
						{
							type: 'select',
							props: {
								id: 'vitesse-select',
								options: vitesseCsvSelectData,
								name: "par_vitesse",
								placeholder: t('viewProductsPage.topSection.byVitesseDropDown'),
								isClearable: true,
							}
						},
						{
							type: 'select',
							props: {
								id: "department-select",
								options: departmentSelectData,
								name: "par_rayon",
								placeholder: t('viewProductsPage.topSection.byDepartmentDropdown'),
								isClearable: true,
							}
						}
					]}
				/>
				<div id="nw-operation-product-list-container" className="nw-operation-product-list-container">
					<Row xs={1} sm={2} md={3} lg={4} xl={5} xxl={6} className="gy-4">
						<Col className="list-product-item">
							<Form
								className="list-product-item-card p-3 px-4 d-flex rounded-1 shadow flex-column impression-multiple-card text-white"
								onSubmit={(e) => handleMultiplePrint(e)}
							>
								<div className="fs-5 d-flex justify-content-center fw-bolder impression-multiple-card-title my-2">
									{ t('viewProductsPage.multipleImpressionTitle') }
								</div>

								<div className="d-flex flex-column row-gap-3 mt-3">
									<Select
										options={vitesseCsvSelectData}
										name="vitesse"
										placeholder={ t('viewProductsPage.vitesseField') }
										classNames={{
											control: (state) => 'rounded-0',
											container: (state) => 'text-black'
										}}
										classNamePrefix="impression-multiple-select"
										isClearable={true}
									/>
									<Select
										options={departmentSelectData}
										name="rayon"
										placeholder={ t('viewProductsPage.deptField') }
										classNames={{
											control: (state) => 'rounded-0',
											container: (state) => 'text-black'
										}}
										classNamePrefix="impression-multiple-select"
										isClearable={true}
									/>
									<Select
										options={formatSelectData}
										name="format"
										placeholder={ t('formatDropdownLabel') }
										onChange={getFormatData}
										classNames={{
											control: (state) => 'rounded-0',
											container: (state) => 'text-black'
										}}
										classNamePrefix="impression-multiple-select"
										value={selectedFormat}
									/>
									<Select
										id="myselectbox"
										options={OrientationSelectDataFinal}
										name="orientation"
										placeholder={ t('orientationDropdownLabel') }
										classNames={{
											control: (state) => 'rounded-0',
											container: (state) => 'text-black'
										}}
										classNamePrefix="impression-multiple-select"
										isClearable={true}
									/>
								</div>
								<div className="mt-3">
									<NwCustomCheckbox
										label={ t('imagecheckboxLabel') }
										OnChange={setImpressionMultipleWithImage}
										initialIsCheck={impressionMultipleWithImage}
										variant="secondary"
										checkboxSize="nw-md"
									/>
								</div>
								<button className="btn btn-light mt-4 rounded-0 impression-multiple-add-to-cart">
								{ t('addToCartButtonLabel') }
									{newIsLoadingMultiple ? (
									<span
										style={{ marginLeft: "10px" }}
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
									) : null}
								</button>
							</Form>
						</Col>
						{posts?.map((item, index) => (
							<OperationProductsCard
								key={index}
								item={item}
								handleSubmit={handleSubmit}
								handleInputChange={handleInputChange}
								handleRadioChange={handleRadioChange}
								isChecked={isChecked}
								selectedFormatValues={selectedFormatValues}
								formatSelectData={formatSelectData}
								selectedOrientationValues={selectedOrientationValues}
								OrientationSelectDataFinal={OrientationSelectDataFinal}
								addToCartLoading={addToCartLoading}
								redirectToEditPage={redirectToEditPage}
								redirectToPreviewPage={redirectToPreviewPage}
								editPosterData={
									{
									endOn: endOn,
									operationId: operationId,
									startOn: startOn
									}
								}
							/>
						))}
					</Row>
				</div>
				<br></br>
				<br></br>

				<div className="paginationDebug" style={{ display: "none" }}>
				<p>totalItems: {totalItems}</p>
				<p>postsPerPage: {postsPerPage}</p>
				<p>pageCount: {pageCount}</p>
				</div>

				<ResponsivePagination
					previousLabel={t("back")} 
					nextLabel={t("next")} 
					current={paginateCurrentPage}
					total={pageCount}
					onPageChange={setCurrentPagePaginate}
				/>
			</div>
		</div>
	);
});

export default OperationProducts;
