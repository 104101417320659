import { useEffect, useState, useRef, useContext, Fragment } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { getDataJson, convertDate, __js_priceConvertToFloat, __js_splitCurrency, formatLabelforFormatPreview, generateConfigFormValuesJsonByFormFieldJson, validateFormValuesByFormFieldJson, isEmptyObject } from "../../../helpers/helpers";
import { Form, Row, Col, ListGroup, Tab, Button, Alert, Nav } from "react-bootstrap";
import EditTabItem from "../../../components/Posters/EditTab/EditTabItem";
import { getBackendUrl, getEventTargetOrigin, getDebugMode } from "../../../config";
import EditorIframePreview from "../../../components/Editor/EditorIframePreview";
import {
    triggerToast,
    getRouteDataFromRouteId
} from "../../../helpers/helpers";

//// LOGOS /////
import { 
    Coin, 
    Image, 
    LayoutTextWindowReverse,
    ArrowLeft, 
    FileEarmarkFill, 
    ArrowClockwise, 
    Window 
} from "react-bootstrap-icons";
import LegalMentionsLogo from "../../../components/_utilityComponents/Poster/TabLogos/LegalMentions/LegalMentionsLogo";
import TextesLogo from "../../../components/_utilityComponents/Poster/TabLogos/TextesLogo";
///////////////

import  { getCharteGraphiqueAndMechaniquePrixByCompanyId } from "../../../lib/utils/discount_reviewProducts";
import Gencode from "../../../components/Posters/PosterEditComponent/GenCode";
import LegalMentions from "../../../components/Posters/PosterEditComponent/LegalMentions/LegalMentions";

import { getDiscountDataById } from "../../../services/discount";
import { getTemplateExtensionById } from "../../../services/template_extension";
import FormFieldContainer from "../../../components/Posters/FormFieldContainer";

import {
	getPartialPriceHtml,
	getDefaultPosterData,
	getTemplateGroups,
	getDefaultTemplateGroupBodyData,
    getDiscounts,
    getDefaultDiscountBodyData,
	handleSearchData,
	handleSortData,
	handleFetchMoreData,
    getDefaultDateObj,
    getBarcode,
    productByEan,
    translateOrientationOptions
} from "../../../services/posters";

import {
    saveSocialMediaProduct,
    downloadSocialMediaPosterPack,
    downloadSocialMediaPosterPackByQueue,
    getSocialMediaDiscounts,
} from '../../../services/social_media';

import { getTemplateGroupMetadata } from "../../../services/template_group";
import { Base64 } from "js-base64";
import PreviewList from "../../../components/Posters/PreviewList/PreviewList";

////// NW CUSTOM FORM UI //////
import { getProductLocalAssetsById, getProductLocaleBarcodeFromEan } from "../../../services/productLocal";
import NwCustomSelect from "../../../components/_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect";
import NwCustomInput from "../../../components/_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";
import NwCustomImageDropzone from "../../../components/_utilityComponents/NwCustomImageDropzone/NwCustomImageDropzone";
import NwCustomDatePicker from "../../../components/_utilityComponents/NwFormsUi/CustomDatePicker/NwCustomDatePicker";
import NwCustomCheckbox from "../../../components/_utilityComponents/NwFormsUi/CustomCheckbox/NwCustomCheckbox";
import { NwLoaderSpinner, NwSocialMediaCoverTab } from "../../_utilityComponents/NwIcons/NwIcons";
import NameWithSearch from "../../../components/Posters/PosterEditComponent/NameWithSearch";
import NwCustomModal from "../../../components/_utilityComponents/NwCustomModal/NwCustomModal";

// SOCIAL MEDIA
import PlatformFormatTab from "../PlatformFormatTab/PlatformFormatTab";
import TemplateGroupModels from "../TemplateGroupModels"
import CoverTab from '../CoverTab';

// CONSTANTS
import { moduleTypes } from "../../../constants/nw/nw-constants";

// Contexts
import useSocialMediaEditorContext from "../../../hooks/useSocialMediaContext";
import { useMainLayoutContext } from "../../../hooks/useMainLayoutContext";
//////////////////////////////
const SocialMediaEditor = (props) => {
    const { 
        initialData,
        mode, 
        id,
        socialMediasData,
        setSocialMediasData,
        existingCategories,
        currentSelectedTemplateGroup,
        setCurrentSelectedTemplateGroup,
        templateGroupCovers,
        setTemplateGroupCovers,
        packGroups,
        setPackGroups,
        currentSelectedThemeType,
    } = useSocialMediaEditorContext();
    const packGroupOptions = packGroups.map(packGroup => {
        return {
            "label" : packGroup?.name,
            "value" : packGroup?.id
        }
    });

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { currentPlatform, setFocusBlockData, setShowFooter } = useMainLayoutContext();
    const queryParams = new URLSearchParams(location.search);
    const { userNw } = useAuth(); //Get user data from Auth Context
    const [ dataJson, SetDataJson ] = useState(null); 
    const [ isInitialLoad, setIsInitialLoad ] = useState(true); //Used when All data have been fetched initially
    const params = useParams();

    //DATA FROM URL PARAMS
    const endOn = queryParams.get("end_on");
    const operationId = queryParams.get("operation_id");
    // const productId = queryParams.get("product_id") ? queryParams.get("product_id") : getDefaultPosterData().productId;
    const productId = id;
    const companyId = queryParams.get("company_id");
    const startOn = queryParams.get("start_on");
    const formatId = queryParams.get("format") ?? getDefaultPosterData().format.value;
    const orientationQuery = queryParams.get("orientation") ?? getDefaultPosterData().orientation;
    let onSaveAction = queryParams.get("onSave") ??  getDefaultPosterData().onSaveAction;
    if (mode === "edit") onSaveAction = "EDIT";
    const fromPosterAction = queryParams.get("from") ??  'posters';
    let showImageProductQuery = queryParams.get("showImageProduct");
    if (!showImageProductQuery) {
        showImageProductQuery = getDefaultPosterData().showImageProduct;
    } else {
        showImageProductQuery = showImageProductQuery === "true";
    }
    const isNew = props.new;
    const productLocalEan = queryParams.get('productLocalEan');

    //UI/UX Utils states
    const [ iframeIsLoading, SetIframeIsLoading ] = useState(true); // used for the iframe itself to load
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isAddingToCart, setIsAddingToCart ] = useState(false);
    const [ showHeaderName, setShowHeaderName ] = useState({
		type: 'name',
		value: false
	});
	const [ showHeaderDate, setShowHeaderDate ] = useState({
		type: 'date',
		value: false
	});
    const [ showImageProduct, setShowImageProduct ] = useState(showImageProductQuery)

    // SOCIAL MEDIA STATES
    const [ postTypeIframeRefs, setPostTypeIframeRefs ] = useState({});
    const [ hiddenPostTypeIframeRefs, setHiddenPostTypeIframeRefs ] = useState({});

    // Data States Original
    const [originalDiscountId, setOriginalDiscountId] = useState(null);
    const [originalDiscountName, setOriginalDiscountName] = useState(null);
    const [originalDiscountFormValuesJson, setOriginalDiscountFormValuesJson] = useState(null);
    const [originalTemplateExtensionId, setOriginalTemplateExtensionId] = useState(null);
    const [originalTemplateExtensionName, setOriginalTemplateExtensionName] = useState(null);
    const [originalTemplateExtensionFormValuesJson, setOriginalTemplateExtensionFormValuesJson] = useState(null);
    const [originalChartesGraphiqueOption, setOriginalChartesGraphiqueOption ] = useState(null);
    const [originalUploadedProductImage, setOriginalUploadedProductImage] = useState(null);
    const [originalUploadedPriceImage, setOriginalUploadedPriceImage] = useState(null);
    const [originalDiscountOption, setOriginalDiscountOption] = useState(null);
    const [originalShowHeaderName, setOriginalShowHeaderName] = useState(false);
	const [originalShowHeaderDate, setOriginalShowHeaderDate] = useState(false);

    ///////////// React Select Options States ///////////////////
    //FORMAT OPTIONS
    const [formatOptions, setFormatOptions] = useState(null);
    const [templateExtensionFormats, setTemplateExtensionFormats] = useState(null);
    const [selectedFormatOption, setSelectedFormatOption] = useState(null);
    const [isFormatLoading, setIsFormatLoading] = useState(true);

    //ORIENTATION OPTIONS
    const [orientationOptions, setOrientationOptions] = useState(null);
    const [selectedOrientationOption, setSelectedOrientationOption] = useState(null);
    const [isOrientationLoading, setIsOrientationLoading] = useState(true);

    //CHARTES GRAPHIQUES OPTIONS
    const [baseTemplateGroupOptions, setBaseTemplateGroupOptions] = useState(null);
    const [usingTemplateExtensionTemplateGroups, setUsingTemplateExtensionTemplateGroups] = useState(false);
    const [chartesGraphiquesOptions, setChartesGraphiquesOptions] = useState(null);
    const [selectedOptionChartesGraphique, setSelectedOptionChartesGraphique] = useState(null); // To keep track of the selected option
    const [ isChartesGraphiquesLoading, setIsChartesGraphiquesLoading ] = useState(true);
    const [chartesGraphiquesBodyData, setChartesGraphiquesBodyData] = useState(null);
	const [templateGroupMetaData, setTemplateGroupMetaData] = useState(null);

    // SOCIAL MEDIA PRODUCT CATEGORY
    const [savePackGroup, setSavePackGroup] = useState(null);
    const [newPackGroupName, setNewPackGroupName] = useState("");


    // DATE
    const [infoDates, setInfoDates] = useState(getDefaultDateObj());

    // DISCOUNT
    const [discountOptions, setDiscountOptions] = useState(null);
    const [baseDiscountOptions, setBaseDiscountOptions] = useState(null);
    const [usingTemplateExtensionDiscounts, setUsingTemplateExtensionDiscounts] = useState(false);
    const [selectedOptionDiscount, setSelectedOptionDiscount] = useState(null); // To keep track of the selected option
    const [discountFormValuesJson, setDiscountFormValuesJson] = useState({});
    const [discountFormFieldJson, setDiscountFormFieldJson] = useState([]);
    const [discountGroupedFormFieldJson, setDiscountGroupedFormFieldJson] = useState({});
	const [isDiscountLoading, setIsDiscountLoading] = useState(true);
    const [discountBodyData, setDiscountBodyData] = useState(null);
    const [useAjaxForFormValuesChangeDiscount, setUseAjaxForFormValuesChangeDiscount] = useState(true);
 
    // TEMPLATE EXTENSION
    const [templateExtensionOptions, setTemplateExtensionOptions] = useState(null);
    const [selectedOptionTemplateExtension, setSelectedOptionTemplateExtension] = useState(null);
    const [templateExtensionFormValuesJson, setTemplateExtensionFormValuesJson] = useState({});
    const [templateExtensionFormFieldJson, setTemplateExtensionFormFieldJson] = useState([]);
    const [templateExtensionGroupedFormFieldJson, setTemplateExtensionGroupedFormFieldJson] = useState({});
	const [isTemplateExtensionLoading, setIsTemplateExtensionLoading] = useState(true);
    const [templateExtensionOrientationOptionsByFormat, setTemplateExtensionOrientationOptionsByFormat] = useState(null);
    const [templateExtensionNotice, setTemplateExtensionNotice] = useState(null);

    // PRODUCT UPLOAD IMAGES
    const [uploadedProductImage, setUploadedProductImage] = useState(null);
    const [uploadedPriceImage, setUploadedPriceImage] = useState(null);

	// VOLUME AND VOLUME UNIT OPTIONS
	const [volume, setVolume] = useState(null); //Volume Value
	const [volumeUnitOptions, setVolumeUnitOptions] = useState([ //Volume Unit
		{value: 'KG', label: 'kg'},
		{value: 'L', label: 'Litre'},
		{value: 'G', label: 'g'}
	]);
    const [volumeText, setVolumeText] = useState(""); //Also known as libelle Volume
    const [hideVolumeText, setHideVolumeText] = useState(false); // Show or hide libelle volume

    // GENCODE STATES
    const [ gencodeData, setGencodeData ] = useState(null);
    const [ eanValue, setEanValue ] = useState("");

    // Define function states (States to be used to set data to iframe)
    const [productName, SetProductName] = useState(null);
    const [productDescription, SetProductDescription] = useState(null);
    const [productPrice, SetProductPrice] = useState(null);
	const [productPriceEvolutel, setProductPriceEvolutel] = useState(null);
    const [extraPriceInfo, setExtraPriceInfo] = useState("");
    const [packSize, setPackSize] = useState(null);

    // SELECTED MENTION LEGALES DATA
    const [selectedLegalMentions, setSelectedLegalMentions] = useState([]);
	const [ additionalLegalMentions, setAdditionalLegalMentions ] = useState('');
    const [templateExtensionLegalMentions, setTemplateExtensionLegalMentions] = useState([]);
    const [discountLegalMentions, setDiscountLegalMentions] = useState([]);

    // Updated Data
    const [ productData, setProductData ] = useState({});
    const [ imagesData, setImagesData ] = useState({});
    const [ tplGroupDataJson, setTplGroupData ] = useState({});
    const [ discountDataJson, setDiscountData ] = useState({});
    const [ templateExtensionDataJson, setTemplateExtensionData] = useState({});


    // #region DOWNLOAD SOCIAL MEDIA POSTER STATES
    const [ downloadData, setDownloadData ] = useState({});
    const [shouldStartDownload, setShouldStartDownload] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);
	const [ previewContainerCurrentActive, setPreviewContainerCurrentActive ] = useState("all");
    // #endregion

	// States to show external form fields, not added from discount data form field json
	// Handling whether datetime fields should be added or not for social media discount
	const [showDiscountDateTimeFields, setShowDiscountDateTimeFields] = useState(false);

    const [closeModal, setCloseModal] = useState(true);

	const resetExternalFormFields = () => {
		setShowDiscountDateTimeFields(false);
	}

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'dataUrlFromIframe') {
                handleIframeDownloadData(event.data.dataUrl, event.data.postTypeId);
            }

			if (event.data.type === "discount-action"){
				if (event.data.action === "add-discount-datetime-fields") {
					setShowDiscountDateTimeFields(true);
				}
			}
        }
        window.addEventListener('message' , handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [])
    

    //////////// ON INITIAL LOAD /////////////////
    useEffect(() => {
        setShowFooter(false);
        const currentRoute = getRouteDataFromRouteId('social_media');
        setFocusBlockData({
            blockIdentifier: currentRoute?.id,
            title: currentRoute?.title,
            icon: currentRoute?.icon,
            menuItems: currentRoute?.subMenu
        })

        //GET DISCOUNTS
        getSocialMediaDiscounts(getDefaultDiscountBodyData()).then((data) => {
            setDiscountOptions(data?.items);
            setBaseDiscountOptions(data?.items);
            setDiscountBodyData(getDefaultDiscountBodyData());
            setIsDiscountLoading(false);
        })
        .catch((error) => {
            setIsDiscountLoading(false);
        })

		// GET TEMPLATE GROUPS
		getTemplateGroups(getDefaultTemplateGroupBodyData()).then((data) => {
			setChartesGraphiquesOptions(data?.items);
            setBaseTemplateGroupOptions(data?.items);
            if (isNew) {
                if (data?.items.length > 0) {
                    setOriginalChartesGraphiqueOption(data?.items[0]);
                }
            }
            setChartesGraphiquesBodyData(getDefaultTemplateGroupBodyData());
			setIsChartesGraphiquesLoading(false);
		})
		.catch((error) => {
			setIsChartesGraphiquesLoading(false);
		});

		//GET Price Mechanic By Company Id
        getCharteGraphiqueAndMechaniquePrixByCompanyId(companyId)
            .then((data) => {
                setTemplateExtensionOptions(data.templateExtensions);
                setIsTemplateExtensionLoading(false);
            })
            .catch((error) => {
                // In case there is an error during the execution of your async function,
                // it will be caught here.
                console.error("An error occurred:", error);
                setIsTemplateExtensionLoading(false);
                // Handle the error (e.g., set error message state, show a notification, etc.)
        });
        
        if (true)
        {
            // GET CURRENT PRODUCT DETAILS + CURRENT CHARTE GRAPHIQUES + FORMAT + ORIENTATION
            const data = initialData;
            if (!data) {
                setIsInitialLoad(false);
                return;
            };

            SetProductName(data.product_name);
            setEanValue(data?.ean ?? "");
            SetProductDescription(data.product_description);
            SetProductPrice(data.product_price);
            setOriginalDiscountId(data.selectedDiscountData?.id);
            setOriginalDiscountName(data.selectedDiscountData?.name);
            setDiscountFormFieldJson(data.selectedDiscountData?.formFields);
            setDiscountGroupedFormFieldJson(data.selectedDiscountData?.groupedFormFields);
            setOriginalDiscountOption(data.selectedDiscountData);
            setOriginalDiscountFormValuesJson(data.discountConfigFormValues);
            // setOriginalTemplateExtensionId(data.selectedTemplateExtensionData?.id);
            // setOriginalTemplateExtensionName(data.selectedTemplateExtensionData?.name);
            // setTemplateExtensionFormFieldJson(data.selectedTemplateExtensionData?.formFields);
            // setTemplateExtensionGroupedFormFieldJson(data.selectedTemplateExtensionData?.groupedFormFields);
            // setTemplateExtensionNotice(data.selectedTemplateExtensionData?.notice);
            // setOriginalTemplateExtensionFormValuesJson(data.templateExtensionConfigFormValues);
            setOriginalChartesGraphiqueOption(data.selectedTemplateGroupData);
            setCurrentSelectedTemplateGroup(data.selectedTemplateGroupData);
            setUseAjaxForFormValuesChangeDiscount(Boolean(data.selectedDiscountData?.useAjaxForFormValuesChange))

            const selectedLegalMentions = data.selectedLegalsData;
            if (selectedLegalMentions) {
                setSelectedLegalMentions(selectedLegalMentions ? selectedLegalMentions : []);
                const additionalLegalMentions = selectedLegalMentions.additional?.content ? selectedLegalMentions.additional?.content : "";
                setAdditionalLegalMentions(additionalLegalMentions);
            }
            
            const volumeValue = data.volumeValue;
            const volumeUnit = data.volumeUnit;
            setVolume({
                value: volumeValue,
                unit: {
                    'value' : volumeUnit,
                    'label' : volumeUnit?.toLowerCase()
                }
            });
            const volumeText = data?.volumeText ?? "";
            const hideVolumeText = Boolean(data.customPosterData?.hideVolumeText);
            setVolumeText(volumeText);
            setHideVolumeText(hideVolumeText);

            const extraPriceInfo = data?.extraPriceInfo !== null ? data.extraPriceInfo : "";
            const packSize = data.packSize;
            setExtraPriceInfo(extraPriceInfo);
            setPackSize(packSize ?? 0);

            // SET START DATE AND END DATE INTO FORM with transformation done to accomodate to the data being manipulated
            let startDate = data.customPosterData?.startDate;
            let endDate = data.customPosterData?.endDate;
            if (startDate && endDate) {
                startDate = startDate.split('-');
                endDate = endDate.split('-');
                setInfoDates({'startDate': new Date(startDate[0], startDate[1] - 1, startDate[2]), 'endDate' : new Date(endDate[0], endDate[1] -1, endDate[2])});
            }
            
            // SET ORIENTATION AND FORMAT INTO FORM ACCORDINGLY
            // const FormatOptions = data.selectedTemplateGroupData?.metadata?.format?.select;
            // const OrientationOptions = data.selectedTemplateGroupData?.metadata?.orientation?.byFormatselect[formatId];

            // if (FormatOptions) {
            //     setSelectedFormatOption(FormatOptions.find(format => format.value === formatId));
            // }
            // if (OrientationOptions) {
            //     setOrientationOptions(translateOrientationOptions(OrientationOptions, t));
            //     setSelectedOrientationOption(OrientationOptions.find(orientation => orientation.value === orientationQuery));
            // }

            // SET INITIAL ASSETS DATA
            if (data.productAssetsData) {
                const productImage = data.productAssetsData?.product_image?.[0];
                if (productImage && typeof productImage === 'object' && !Array.isArray(productImage)){
                    setOriginalUploadedProductImage([{
                        imageCdnUrl: data.productAssetsData.product_image?.[0]?.complete_url ?? null,
                        imageOutputPath: data.productAssetsData.product_image?.[0]?.assetSavePath ?? null
                    }]);
                }

                const priceImg = data.productAssetsData?.price_image?.[0];
                if (priceImg && typeof priceImg === 'object' && !Array.isArray(priceImg)){
                    setOriginalUploadedPriceImage([{
                        imageCdnUrl: data.productAssetsData?.price_image?.[0]?.complete_url ?? null,
                        imageOutputPath: data.productAssetsData?.price_image?.[0]?.assetSavePath ?? null
                    }]);
                }
            }

            ///// CONDITION BASED ON 'onSaveAction'
            if (data.customPosterData) {
                setOriginalShowHeaderName(data.customPosterData?.showHeaderName);
                setOriginalShowHeaderDate(data.customPosterData?.showHeaderDate);
            } else {
                const selectedTemplateGroupData = data?.selectedTemplateGroupData;
                if (selectedTemplateGroupData) {
                    const rendererLayoutConfig = selectedTemplateGroupData?.rendererLayoutConfiguration;
                    if (rendererLayoutConfig) {
                        setOriginalShowHeaderDate({type: 'date', value: rendererLayoutConfig.showDateHeader});
                        setOriginalShowHeaderName({type: 'name', value: rendererLayoutConfig.showNameHeader});
                    }
                }
            } 

            // SET DISCOUNT OTHER DATA (E.G LEGAL MENTIONS)
            const discountData = data.selectedDiscountData;
            const discountLegalMentions = discountData?.legalNotices;

            if (discountLegalMentions) {
                if (discountLegalMentions.length > 0) {
                    setDiscountLegalMentions(discountLegalMentions);
                }
            }

            // // SET TEMPLATE EXTENSION OTHER DATA AND ALLOWED DATA
            // const templateExtensionData = data.selectedTemplateExtensionData;
            // const formats = templateExtensionData?.formats;
            // const discounts = templateExtensionData?.discounts;
            // const templateGroups = templateExtensionData?.templateGroups;
            // const orientationOptionsByFormat = templateExtensionData?.orientationOptionsByFormat;
            // const selectedFormatId = parseInt(selectedFormatOption?.value ? selectedFormatOption.value : formatId);
            // const notice = templateExtensionData?.notice;
            // const templateExtensionLegalMentions = templateExtensionData?.legalNotices;
            
            // if (formats) {
            //     if (formats.length > 0) {
            //         setFormatOptions(formats);
            //         setTemplateExtensionFormats(formats);
            //         // check if current selected format is allowed by the template extension formats
            //         const templateExtensionFormatsIds = formats.map((format) => format.id);
            //         // change the selected format to the first of template extension formats if previous selected is not allowed.
            //         if (!templateExtensionFormatsIds.includes(selectedFormatId)) {
            //             setSelectedFormatOption(formats[0]);
            //         } else {
            //             // in this case selectedFormatId might be from query so force a set
            //             const selectedFormatFilteredArray = formats.filter((format) => format.id === selectedFormatId);
            //             if (selectedFormatFilteredArray.length > 0) setSelectedFormatOption(selectedFormatFilteredArray[0]);
            //             if (orientationOptionsByFormat) {
            //                 setSelectedOrientationOption(orientationOptionsByFormat[selectedFormatId][0]);
            //                 setOrientationOptions(translateOrientationOptions(orientationOptionsByFormat[selectedFormatId], t));
            //             }
            //         }
            //     }
            // }

            // if (orientationOptionsByFormat) {
            //     setTemplateExtensionOrientationOptionsByFormat(orientationOptionsByFormat);
            // }

            // if (discounts) {
            //     if (discounts.length > 0) {
            //         setUsingTemplateExtensionDiscounts(true);
            //         setDiscountOptions(discounts);
            //         if (selectedOptionDiscount) {
            //             // check if current selected discount is allowed by the template extension
            //             const templateExtensionDiscountIds = discounts.map(discount => discount.id);
            //             if (!(templateExtensionDiscountIds.includes(selectedOptionDiscount.id))) {
            //                 setSelectedOptionDiscount(discounts[0]);
            //                 setOriginalDiscountOption(discounts[0]);
            //             }
            //         } else {
            //             setSelectedOptionDiscount(discounts[0]);
            //             setOriginalDiscountOption(discounts[0]);
            //         }
            //     }
            // }

            // if (templateGroups) {
            //     if (templateGroups.length > 0) {
            //         setUsingTemplateExtensionTemplateGroups(true);
            //         setChartesGraphiquesOptions(templateGroups);
            //         if (selectedOptionChartesGraphique) {
            //             const templateExtensionTemplateGroupIds = templateGroups.map(templateGroup => templateGroup.id);
            //             if (!(templateExtensionTemplateGroupIds.includes(selectedOptionChartesGraphique.id))) {
            //                 setOriginalChartesGraphiqueOption(templateGroups[0]);
            //             }
            //         } else {
            //             setOriginalChartesGraphiqueOption(templateGroups[0]);
            //         }
            //     }
            // }

            // if (notice) {
            //     setTemplateExtensionNotice(notice);
            // } else {
            //     setTemplateExtensionNotice(null);
            // }
            
            // if (templateExtensionLegalMentions) {
            //     if (templateExtensionLegalMentions.length > 0) {
            //         setTemplateExtensionLegalMentions(templateExtensionLegalMentions);
            //     }
            // }
        } else {
            // TO_DO_NW @priyesh this might be redundan... to investigate
            setIsInitialLoad(false);
            getTemplateGroupMetadata(0).then((metadata) => {
                setTemplateGroupMetaData(metadata);
                const OrientationOptions = metadata?.orientation?.byFormatselect[formatId];
                setOrientationOptions(translateOrientationOptions(OrientationOptions, t));
            })
            setSelectedFormatOption(getDefaultPosterData().format);
            setSelectedOrientationOption(orientationQuery)
        }

        setIsInitialLoad(false);
    }, [initialData]);

    /// Use Effect to make sure Iframe has all the data needed to properly run
    useEffect(() => {
        if (userNw && productId && selectedFormatOption && selectedOrientationOption && originalShowHeaderDate && originalShowHeaderName)
        {
            setIsInitialLoad(false);
        }

    }, [userNw, productId, selectedFormatOption, selectedOrientationOption, originalShowHeaderDate, originalShowHeaderName])

    /////////////////////////////////////////////////

    /////// USE MEMO TO HANDLE REFERENCE TO UPLOADED PRODUCT IMAGE
    // const memoizedInitialUploadedInfos = useMemo(() => {
    //     return originalUploadedProductImage ? [originalUploadedProductImage] : [];
    // }, [originalUploadedProductImage]);

    ///// SOCIAL MEDIA EFFECT //////
    // useEffect(() => {
    //     const newPostTypeIframeRefs = {};
    //     Object.values(socialMediasData).forEach(socialMedia => {
    //         Object.values(socialMedia.postTypes).forEach(postType => {
    //             if (postType.selected) {
    //                 newPostTypeIframeRefs[postType.id] = createRef(postType.id);
    //             }
    //         })
    //     })
    //     setPostTypeIframeRefs(newPostTypeIframeRefs);
    //     updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, selectedLegalMentions, imagesData);
    //     SetIframeIsLoading(true);
    // }, [socialMediasData])

    function handleIframeRef(iframeRef, postTypeId, all = false) {
        updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, selectedLegalMentions, imagesData);
        setPostTypeIframeRefs(prevPostTypeIframeRefs => {
            const newPostTypeIframeRefs = {...prevPostTypeIframeRefs};
            if (all) {
                newPostTypeIframeRefs[postTypeId] = iframeRef;
            } else {
                newPostTypeIframeRefs[`all-${postTypeId}`] = iframeRef;
            }
            return newPostTypeIframeRefs;
        })        
    }

    function handleHiddenIframeRef(iframeRef, postTypeId) {
        setHiddenPostTypeIframeRefs(prevHiddenPostTypeIframeRefs => {
            const newHiddenPostTypeIframeRefs = {...hiddenPostTypeIframeRefs};
            newHiddenPostTypeIframeRefs[postTypeId] = iframeRef;
            return newHiddenPostTypeIframeRefs;
        })       
    }

    ///// HANDLING ORIGINAL DISCOUNT DATA ON OBTAINED////////////////
    useEffect(() => {
        if (originalDiscountFormValuesJson)
        {
            setDiscountFormValuesJson(originalDiscountFormValuesJson);
        }
    }, [originalDiscountFormValuesJson])

    ///// HANDLING ORIGINAL TEMPLATE EXTENSION DATA ON OBTAINED////////////////
    useEffect(() => {
        if (originalTemplateExtensionId && originalTemplateExtensionName)
        {
            setSelectedOptionTemplateExtension({value: originalTemplateExtensionId,label: originalTemplateExtensionName});
        }
    }, [originalTemplateExtensionId, originalTemplateExtensionName])

    useEffect(() => {
        if (originalTemplateExtensionFormValuesJson)
        {
            setTemplateExtensionFormValuesJson(originalTemplateExtensionFormValuesJson);
        }
    }, [originalTemplateExtensionFormValuesJson])
    /////////////////////////////////////////////////

    useEffect(() => {
        // HANDLE IFRAME ON FIRST LOAD
        // Check if the iframe is currently available
        Object.values(postTypeIframeRefs).forEach(iframeRef => {
            if (iframeRef.current) {
                const iframeElement = iframeRef.current;
            
                // Define a function that will be called when the iframe finishes loading
                const iframeLoadHandler = () => {
                    SetIframeIsLoading(false);
                    // Here, you can handle other logic after loading completes
                    if (productLocalEan) {
                        setEanValue(productLocalEan);
                        async function getProductLocalData(productLocalEan) {
                            const response = await productByEan(productLocalEan);
                            if (response?.items?.length > 0) {
                               const productLocalData = response.items[0];
                               const assetsData = await getProductLocalAssetsById(productLocalData.id);
                               const barcode = await getProductLocaleBarcodeFromEan(productLocalEan);
                               productLocalData.productAssetsData = assetsData;
                               productLocalData.barcodeGenerated = barcode;
        
                               getGencodeData(productLocalData);
                            } 
                        }
        
                        getProductLocalData(productLocalEan);
                    }
                };
                // Add the event listener for the 'load' event
                iframeElement.addEventListener('load', iframeLoadHandler);
                // Cleanup: remove the event listener when the component unmounts
                return () => {
                    iframeElement.removeEventListener('load', iframeLoadHandler);
                };
            }
        })

    }, [postTypeIframeRefs, userNw, isInitialLoad]);

    // Data Updators
    useEffect(() => {
        updateProductData();
    }, [productName, productDescription, productPrice]);

    useEffect(() => {
        updateImagesData();
    }, [uploadedProductImage, uploadedPriceImage])

    useEffect(() => {
        updateDiscountData();
    }, [discountFormValuesJson]);

    useEffect(() => {
        updateTemplateExtensionData();
    }, [templateExtensionFormValuesJson]);

    //When selected State Changes for Chartes Graphiques
    useEffect(() => {
        if (tplGroupDataJson)
        {
            updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, selectedLegalMentions, imagesData);
        }
    }, [tplGroupDataJson]);

	/////////////////////// LOGIC STATE USE-EFFECTS //////////////////////////////////
	/// Used when format is selected, to re-populate orientation options
	useEffect(() => {
		if (selectedFormatOption && templateGroupMetaData && !isInitialLoad) {
            SetIframeIsLoading(true);
            updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, selectedLegalMentions, imagesData);
			const selectedFormatId = selectedFormatOption.value;
            // check if the current template extensions have orientation options configured for its allowed formats and choose from that, or else fall back to metadata
            if (templateExtensionOrientationOptionsByFormat) {
                setSelectedOrientationOption(templateExtensionOrientationOptionsByFormat[selectedFormatId][0]);
			    setOrientationOptions(translateOrientationOptions(templateExtensionOrientationOptionsByFormat[selectedFormatId], t));
            } else {
                setSelectedOrientationOption(templateGroupMetaData.orientation.byFormatselect[selectedFormatId][0]);
                setOrientationOptions(translateOrientationOptions(templateGroupMetaData.orientation.byFormatselect[selectedFormatId], t));
            }
		}
	}, [selectedFormatOption])

    useEffect(() => {
        
    }, [formatOptions])
    

    useEffect(() => {
        if (orientationOptions) {
            setIsOrientationLoading(false);
        }
    }, [orientationOptions])

    /// Use effect when orientation is changed
    useEffect(() => {
        SetIframeIsLoading(true);
        
        if (!isInitialLoad){
            // DO THE NECESSARY
            updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, selectedLegalMentions, imagesData);
            // setIframeAspectRatio(handleFormatPreviewOrientation().aspectRatio);
        }
    }, [selectedOrientationOption, isInitialLoad])

	/// Used when template group is selected, to re-populate format options
	useEffect(() => {
		if (templateGroupMetaData)
		{   
            if (templateExtensionFormats) {
                setFormatOptions(templateExtensionFormats);
            } else {
                setFormatOptions(templateGroupMetaData.format.select);
            }
			setIsFormatLoading(false);
		}
	}, [templateGroupMetaData])

	// On Selected Chartes Graphique State Change
	useEffect(() => {
		updateTemplateGroupData();

		if (selectedOptionChartesGraphique)
		{
			if ( selectedOptionChartesGraphique.metadata) {
				setTemplateGroupMetaData(selectedOptionChartesGraphique.metadata);	
			} else {
				getTemplateGroupMetadata(selectedOptionChartesGraphique.id).then((metadata) => {
					setTemplateGroupMetaData(metadata);
				})
			}
		}

	}, [selectedOptionChartesGraphique]);

    useEffect(() => {
        const tplGroupData = {
            "id" : currentSelectedTemplateGroup ? currentSelectedTemplateGroup.id : null
        }

        setTplGroupData(tplGroupData);
    }, [currentSelectedTemplateGroup])

    //When selected State Changes For Discount
    useEffect(() => {
        if (selectedOptionDiscount && !iframeIsLoading && !isInitialLoad )
        {
			setIsDiscountLoading(true);
            const selectedDiscountId = selectedOptionDiscount.id;
            const getAndSetDiscountData = async () => {
                setDiscountFormValuesJson({});
                const discountData = await getDiscountDataById(selectedDiscountId);
                if (!discountData) return;

                const legalMentions = discountData?.legalNotices;
                if(legalMentions) {
                    setDiscountLegalMentions([...legalMentions]);
                    // need to always have them selected by default
                    const newSelectedLegalMentions = {...selectedLegalMentions};
                    let selectedLegals = newSelectedLegalMentions?.legals ?? [];

                    // remove existing template extension legal mention if there are any in the selected array
                    selectedLegals = selectedLegals.filter(selectedLegal => (selectedLegal.source !== 'discount'));
                    let selectedLegalsIds = selectedLegals.map(selectedLegal => selectedLegal.id);

                    legalMentions.forEach((legalMention) => {
                        // check if template extension legal mention is already in selected legals array and set a source to template_extension
                        if (selectedLegalsIds.includes(legalMention.id)) {
                            const existingIndex = selectedLegalsIds.indexOf(legalMention.id);
                            if (selectedLegals[existingIndex].source === "template_extension" || 'templateExtensionId' in selectedLegals[existingIndex]) delete selectedLegals[existingIndex].templateExtensionId;
                            selectedLegals[existingIndex].source = 'discount'
                            selectedLegals[existingIndex].discount = discountData.id;
                        } else {
                            const legalNoticeToAdd = {...legalMention};
                            legalNoticeToAdd.source = "discount";
                            legalNoticeToAdd.templateExtensionId = discountData.id;
                            selectedLegals.push(legalNoticeToAdd);
                        }
                    });
                    newSelectedLegalMentions.legals = selectedLegals;
                    setSelectedLegalMentions(newSelectedLegalMentions);
                } else {
                    setTemplateExtensionLegalMentions([]);
                }
				resetExternalFormFields();
                setUseAjaxForFormValuesChangeDiscount(Boolean(discountData?.useAjaxForFormValuesChange));
                setDiscountFormFieldJson(discountData.formFields);
                setDiscountGroupedFormFieldJson(discountData.groupedFormFields);
            }
            getAndSetDiscountData().then(()=>setIsDiscountLoading(false));
        } else {
            if (!selectedOptionDiscount) {
				resetExternalFormFields();
                setDiscountFormValuesJson({});
                setDiscountFormFieldJson([]);
                setDiscountGroupedFormFieldJson({});
                setDiscountLegalMentions([]);
                // need to always have them selected by default
                const newSelectedLegalMentions = {...selectedLegalMentions};
                let selectedLegals = newSelectedLegalMentions?.legals ?? [];
                selectedLegals = selectedLegals.filter(selectedLegal => (selectedLegal.source !== 'discount'));
                newSelectedLegalMentions.legals = selectedLegals;
                setSelectedLegalMentions(newSelectedLegalMentions);
            }
        }
    }, [selectedOptionDiscount]);

    const getAndSetPartialPriceHtml = async () => {
        const dataForPricePartial = {
            productId : 0,
            companyId: companyId,
            discountValuesJson: JSON.stringify(discountFormValuesJson),
            discountId: selectedOptionDiscount ? selectedOptionDiscount.id : null,
            priceValue: productPrice === '' ? null : productPrice,
            priceImage: uploadedPriceImage ? JSON.stringify(uploadedPriceImage) : null,
            moduleType: moduleTypes.social_media
        };
        const partialPriceHtml = await getPartialPriceHtml(dataForPricePartial);
        if (partialPriceHtml){
            sendPriceHtml(partialPriceHtml.HTML, partialPriceHtml.useImagePrice)
        };
    }

    useEffect(() => {
        if ((Array.isArray(discountFormFieldJson)) &&
        (typeof discountFormValuesJson === 'object') && !iframeIsLoading)
        {
            getAndSetPartialPriceHtml();
        }
    }, [discountFormFieldJson]); // Will not use discountFormValuesJson as dependancy to allow dynamic data change using JS

    useEffect(() => {
        if ((Array.isArray(discountFormFieldJson)) &&
        (typeof discountFormValuesJson === 'object') && !iframeIsLoading)
        {
            if (useAjaxForFormValuesChangeDiscount) {
                getAndSetPartialPriceHtml();
            } else {
                sendPriceAndDiscountByJS(discountFormFieldJson, discountFormValuesJson, productPrice);
            }
        }
    }, [productPrice, discountFormValuesJson])

    // When selected State Changes For Template Extension
    useEffect(() => {
        const getAndSetTemplateExtensionData = async (selectedTemplateExtensionId) => {
            setTemplateExtensionFormValuesJson({})
            const templateExtensionData = await getTemplateExtensionById(selectedTemplateExtensionId);
            if (!templateExtensionData) return;
            const formats = templateExtensionData?.formats;
            const discounts = templateExtensionData?.discounts;
            const templateGroups = templateExtensionData?.templateGroups
            const orientationOptionsByFormat = templateExtensionData?.orientationOptionsByFormat;
            const selectedFormatId = selectedFormatOption.value;
            const legalMentions = templateExtensionData?.legalNotices;
            const notice = templateExtensionData?.notice;
            SetIframeIsLoading(true);
            if (formats) {
                if (formats.length > 0) {
                    setFormatOptions(formats);
                    setTemplateExtensionFormats(formats);
                    
                    // check if current selected format is allowed by the template extension formats
                    const templateExtensionFormatsIds = formats.map((format) => format.id);

                    // change the selected format to the first of template extension formats if previous selected is not allowed.
                    if (!templateExtensionFormatsIds.includes(selectedFormatId)) {
                        setSelectedFormatOption(formats[0]);
                    } else {
                        // selected format option wont change so need to force orientation options and selected one here instead of its useEffect.
                        if (orientationOptionsByFormat) {
                            setSelectedOrientationOption(orientationOptionsByFormat[selectedFormatId][0]);
                            setOrientationOptions(translateOrientationOptions(orientationOptionsByFormat[selectedFormatId], t));
                        }
                    }
                } else {
                    setTemplateExtensionFormats(null);
                    // reset every format options, orientation options and selected orientation to metadata;
                    if (templateGroupMetaData) {
                        const FormatOptions = templateGroupMetaData?.format?.select;
                        const OrientationOptions = templateGroupMetaData?.orientation?.byFormatselect[formatId];
                        if (FormatOptions) {
                            setSelectedFormatOption(FormatOptions.find(format => format.value === parseInt(formatId)));
                        }
                        if (OrientationOptions) {
                            // TO_DO_NW @priyesh setSelectedOrientationOption here , currently bugged because handleFormatPreviewOrientation is undefined
                            // setSelectedOrientationOption(OrientationOptions.find(orientation => orientation.value === orientationQuery));
                        }
                        setFormatOptions(FormatOptions);
                        setOrientationOptions(translateOrientationOptions(OrientationOptions, t));
                    }
                }
            }

            if (orientationOptionsByFormat) {
                setTemplateExtensionOrientationOptionsByFormat(orientationOptionsByFormat);
            } else {
                setTemplateExtensionOrientationOptionsByFormat(null);
            }

            if (discounts) {
                if (discounts.length > 0) {
                    setUsingTemplateExtensionDiscounts(true);
                    setDiscountOptions(discounts);
                    if (selectedOptionDiscount) {
                        // check if current selected discount is allowed by the template extension
                        const templateExtensionDiscountIds = discounts.map(discount => discount.id);
                        if (!(templateExtensionDiscountIds.includes(selectedOptionDiscount.id))) {
                            // setSelectedOptionDiscount(discounts[0]);
                            setOriginalDiscountOption(discounts[0]);
                        }
                    } else {
                        // setSelectedOptionDiscount(discounts[0]);
                        setOriginalDiscountOption(discounts[0]);

                    }
                } else {
                    setDiscountOptions(baseDiscountOptions);
                    // setSelectedOptionDiscount(null);
                    setOriginalDiscountOption(null);
                    setUsingTemplateExtensionDiscounts(false);
                }
            }

            if (templateGroups) {
                if (templateGroups.length > 0) {
                    setUsingTemplateExtensionTemplateGroups(true);
                    setChartesGraphiquesOptions(templateGroups);
                    if (selectedOptionChartesGraphique) {
                        const templateExtensionTemplateGroupIds = templateGroups.map(templateGroup => templateGroup.id);
                        if (!(templateExtensionTemplateGroupIds.includes(selectedOptionChartesGraphique.id))) {
                            // setSelectedOptionChartesGraphique(templateGroups[0]);
                            setOriginalChartesGraphiqueOption(templateGroups[0]);
                        }
                    } else {
                        // setSelectedOptionChartesGraphique(templateGroups[0]);
                        setOriginalChartesGraphiqueOption(templateGroups[0]);
                    }
                } else {
                    setChartesGraphiquesOptions(baseTemplateGroupOptions);
                    // setSelectedOptionChartesGraphique(null);
                    setOriginalChartesGraphiqueOption(null);
                    setUsingTemplateExtensionTemplateGroups(false);
                }
            }

            let selectedLegalMentionsToUpdate = {...selectedLegalMentions};
            if(legalMentions) {
                setTemplateExtensionLegalMentions([...legalMentions]);
                // need to always have them selected by default
                const newSelectedLegalMentions = {...selectedLegalMentions};
                let selectedLegals = newSelectedLegalMentions?.legals ?? [];

                // remove existing template extension legal mention if there are any in the selected array
                selectedLegals = selectedLegals.filter(selectedLegal => (selectedLegal.source !== 'template_extension'));
                let selectedLegalsIds = selectedLegals.map(selectedLegal => selectedLegal.id);

                legalMentions.forEach((legalMention) => {
                    // check if template extension legal mention is already in selected legals array and set a source to template_extension
                    if (selectedLegalsIds.includes(legalMention.id)) {
                        const existingIndex = selectedLegalsIds.indexOf(legalMention.id);
                        // only do this if the existing selected legals is not of source discount because discount legal notices are more important than template extension legal notices
                        if (selectedLegals[existingIndex].source !== "discount") {
                            selectedLegals[existingIndex].source = 'template_extension'
                            selectedLegals[existingIndex].templateExtensionId = templateExtensionData.id;
                        }
                    } else {
                        const legalNoticeToAdd = {...legalMention};
                        legalNoticeToAdd.source = "template_extension";
                        legalNoticeToAdd.templateExtensionId = templateExtensionData.id;
                        selectedLegals.push(legalNoticeToAdd);
                    }
                });
                newSelectedLegalMentions.legals = selectedLegals;
                selectedLegalMentionsToUpdate = newSelectedLegalMentions;
                setSelectedLegalMentions(newSelectedLegalMentions);
            } else {
                setTemplateExtensionLegalMentions([]);
            }

            if (notice) {
                setTemplateExtensionNotice(notice);
            } else {
                setTemplateExtensionNotice(null);
            }

            setTemplateExtensionFormFieldJson(templateExtensionData.formFields);
            setTemplateExtensionGroupedFormFieldJson(templateExtensionData.groupedFormFields);

            return {
                newSelectedLegalMentions: selectedLegalMentionsToUpdate, 
                newTemplateExtensionFormValuesJson: generateConfigFormValuesJsonByFormFieldJson(templateExtensionData.formFields),
            }
        }

        if (selectedOptionTemplateExtension && !isInitialLoad && !iframeIsLoading) {
            setIsTemplateExtensionLoading(true);
            const selectedTemplateExtensionId = selectedOptionTemplateExtension.value;
           
            getAndSetTemplateExtensionData(selectedTemplateExtensionId).then((newData) => {
                setIsTemplateExtensionLoading(false)
                
                const templateExtensionData = {
                    'templateExtensionId' : selectedOptionTemplateExtension ? selectedOptionTemplateExtension.value : null,
                    'templateExtensionFormValuesJson' : newData.newTemplateExtensionFormValuesJson,
                }
                let selectedLegalMentionsToUpdate = newData['newSelectedLegalMentions'];
                updateData(productData, tplGroupDataJson, templateExtensionData, discountDataJson, selectedLegalMentionsToUpdate, imagesData);
            });

        } else {
            SetIframeIsLoading(true);
            // DISSELECT TEMPLATE EXTENSION
            if (!selectedOptionTemplateExtension?.value) {
                setTemplateExtensionFormats(null);
                setChartesGraphiquesOptions(baseTemplateGroupOptions); // reset the template group choices to default
                setDiscountOptions(baseDiscountOptions); // reset the discount options to default
                const FormatOptions = templateGroupMetaData?.format?.select;
                const OrientationOptions = templateGroupMetaData?.orientation?.byFormatselect[formatId];
                setFormatOptions(FormatOptions); // reset the format options to default
                setOrientationOptions(translateOrientationOptions(OrientationOptions, t)); // reset the orientation options to default
                setOriginalChartesGraphiqueOption(originalChartesGraphiqueOption); // reset the selected chartes graphique to default
                setUsingTemplateExtensionDiscounts(false); 
                setUsingTemplateExtensionTemplateGroups(false);
                setTemplateExtensionOrientationOptionsByFormat(null);
                setTemplateExtensionNotice(null);
                setTemplateExtensionLegalMentions([]);

                // reset the template extension form field and values to empty
                setTemplateExtensionFormFieldJson([]);
                setTemplateExtensionGroupedFormFieldJson({});
                setTemplateExtensionFormValuesJson({});

                const templateExtensionDataJson = {
                    'templateExtensionId' :  null,
                    'templateExtensionFormValuesJson' : {},
                }
    
                setTemplateExtensionData(templateExtensionDataJson);
                const discountDataJson = {
                    "discountId": selectedOptionDiscount ? selectedOptionDiscount.id : null,
                    "discountFormValuesJson" : discountFormValuesJson,
                }
    
                // need to always have them selected by default
                const newSelectedLegalMentions = {...selectedLegalMentions};
                let selectedLegals = newSelectedLegalMentions?.legals ?? [];
                selectedLegals = selectedLegals.filter(selectedLegal => (selectedLegal.source !== 'template_extension'));
                newSelectedLegalMentions.legals = selectedLegals;
                setSelectedLegalMentions(newSelectedLegalMentions);
                setDiscountData(discountDataJson);
                updateData(productData, tplGroupDataJson, templateExtensionDataJson, discountDataJson, newSelectedLegalMentions, imagesData);
            }
        }
    }, [selectedOptionTemplateExtension])

    useEffect(() => {
        if ((Array.isArray(templateExtensionFormFieldJson)) &&
        (typeof templateExtensionFormValuesJson === 'object') && !iframeIsLoading)
        {
            sendTemplateExtensionByJs(templateExtensionFormValuesJson);
        }
    }, [templateExtensionFormValuesJson])

	// Apply product image to renderer on uploadedProductImage state change and isInitialLoad false
    useEffect(() => {
        if (!isInitialLoad)
        {
            if (uploadedProductImage) {
                sendProductImage(uploadedProductImage.imageCdnUrl);
                sendShowImageProduct(true);
            } else {
                sendProductImage(null);
                sendShowImageProduct(false);
            }
        }
    }, [uploadedProductImage])

	// Apply price image to renderer on uploadedPriceImage state change and isInitialLoad false
    useEffect(() => {
        if (!isInitialLoad)
        {
            const getAndSetPartialPriceHtml = async () => {
                const dataForPricePartial = {
                    productId : 0,
                    companyId: companyId,
                    discountValuesJson: JSON.stringify(discountFormValuesJson),
                    discountId: selectedOptionDiscount ? selectedOptionDiscount.id : null,
                    priceValue: productPrice === '' ? null : productPrice,
                    priceImage: JSON.stringify(uploadedPriceImage),
                    moduleType: moduleTypes.social_media
                };
                const partialPriceHtml = await getPartialPriceHtml(dataForPricePartial);
                if (partialPriceHtml){
                    sendPriceHtml(partialPriceHtml.HTML, partialPriceHtml.useImagePrice)
                };
            }
            getAndSetPartialPriceHtml();  
        } 
    }, [uploadedPriceImage]);

	// On Show Header Name state change
	useEffect(() => {
		if (!isInitialLoad) {
			sendShowHeader(showHeaderName);
		}
	}, [showHeaderName])

	//On Show Header Date state change
	useEffect(() => {
		if (!isInitialLoad) {
			sendShowHeader(showHeaderDate)
		}
	}, [showHeaderDate])

    //On Show Product Image
    useEffect(() => {
        if (!isInitialLoad) {
            sendShowImageProduct(showImageProduct);
        }
    }, [showImageProduct])


    useEffect(() => {
        if (!iframeIsLoading && !isInitialLoad) {
            async function updateBarcode() {
                // get the barcode
                let barcode = {};
                if (eanValue)
                {
                    barcode = await getBarcode(eanValue, selectedOrientationOption?.value ?? "portrait");
                }
                // post the barcode to iframe
                sendGenCodeBarcode(barcode?.HTML ?? "", eanValue);
            }
            updateBarcode();
        }
    }, [eanValue])
	////////////////////////////////////////////////////////////////////////////////////

    ///////////// HTML Setters to Renderer /////////////
    const sendToAllIframes = (message) => {
        Object.values(postTypeIframeRefs).forEach(postTypeIframeRef => {
            const iframeWindow = postTypeIframeRef?.current?.contentWindow;
            iframeWindow?.postMessage(message, getEventTargetOrigin());
        })
    }

    const sendToAllHiddenIframes = (message) => {
        Object.entries(hiddenPostTypeIframeRefs).forEach(([postTypeId, postTypeIframeRef]) => {
            message.postTypeId = postTypeId;
            const iframeWindow = postTypeIframeRef?.current?.contentWindow;
            iframeWindow?.postMessage(message, getEventTargetOrigin());
        })
    }

    const sendProductName = (name) => {
       sendToAllIframes({
            type: "setProductName",
            name : name
        });
    };
    const sendProductDescription = (description) => {
        sendToAllIframes({
            type: "setProductDescription",
            name : description
        });
    };
    const sendProductImage = (imageCdnUrl) => {
        sendToAllIframes({
            type: "setProductImage",
            imageCdnUrl: imageCdnUrl
        })
    }
    const sendPriceHtml = (partialPriceHtml, useImagePrice = false, splittedCurrency = null) => {
        sendToAllIframes({
            type: "setPriceHtml",
            partialPriceHtml: partialPriceHtml,
            useImagePrice: useImagePrice,
            splittedCurrency: splittedCurrency
        })
    }
	const sendPriceAndDiscountByJS = (discountFormFieldJson, discountFormValuesJson, productPrice) => {
        sendToAllIframes({
            type: "setPriceAndDiscountByJS",
            discountFormFieldJson: discountFormFieldJson,
            discountFormValuesJson: discountFormValuesJson,
            volumeData: volume,
            productPrice: __js_splitCurrency(__js_priceConvertToFloat(productPrice)),
            productPriceValue: __js_priceConvertToFloat(productPrice)
        });
	}
    const sendTemplateExtensionByJs = (templateExtensionFormValuesJson) => {
        sendToAllIframes({
            type: "setTemplateExtensionByJS",
            templateExtensionFormFieldJson: templateExtensionFormFieldJson,
            templateExtensionFormValuesJson: templateExtensionFormValuesJson,
        })
    }
    const sendTemplateExtensionHtml = (templateExtensionId, templateExtensionJsonValues, templateExtensionHtml, toClear = false) => {
        sendToAllIframes({
            type: "setTemplateExtensionHtml",
            templateExtensionId: templateExtensionId,
            templateExtensionJsonValues: templateExtensionJsonValues,
            templateExtensionHtml: templateExtensionHtml,
            toClear: toClear //set to true if need to clear renderer
        });
    }
	const sendGenCodeBarcode = (gencodeBarCodeHTML, eanValue = '') => {
        sendToAllIframes({
            type: "setGenCodeBarCode",
			gencodeBarCodeHTML: gencodeBarCodeHTML,
            eanValue: eanValue
        })
	}
	const sendLegalMentions = (legalMentions, additionalLegalMentions = '') => {
        sendToAllIframes({
            type: "setLegalMentions",
			legalMentions: legalMentions,
			additionalLegalMentions: additionalLegalMentions
        });
	}
	const sendShowHeader = (showHeader) => {
        sendToAllIframes({
            type: "setShowHeader",
			showHeader: showHeader
        });
	}
    const sendInfoDates = (infoDates) => {
        sendToAllIframes({
            type: "setInfoDates",
            infoDates: infoDates
        });
    }
    const sendShowImageProduct = (showImageProduct) => {
        sendToAllIframes({
            type: "setShowImageProduct",
            showImageProduct: showImageProduct
        });
    }
    const sendAdditionalPriceMechanicData = () => {
        sendToAllIframes({
            type: "handleAdditionalPriceMechanicData",
            volumeText: volumeText,
            volumeData: volume,
            packSize: packSize,
            hideLibelleVolume: hideVolumeText,
            productPrice: productPrice,
            extraPriceInfo: extraPriceInfo,
        });
    }
	//////////////////////////////////////////////////////////////////////////////

	//////////// Handle On Show Header /////////////////
	const handleOnShowHeaderChange = (type = 'name', showHeader, updator) => {
		updator({
			type: type,
			value: showHeader
		});
	}
	//////////////////////////////////////////////////

    ///////// Handle Product name input
    const handleProductNameInput = (e) => {
        SetProductName(e.target.value);
    }
    useEffect(() => {
        sendProductName(productName);
    }, [productName]);  
    ////////////////////////////////////  

    /////// Handle Product Description input
    const handleProductDescriptionInput = (e) => {
        sendProductDescription(e.target.value);
        SetProductDescription(e.target.value); 
    }
    useEffect(() => {
        if (productDescription) {
            sendProductDescription(productDescription);
        }
    }, [productDescription]);
    /////////////////////////////////

    ////////////// Handle Product Price input
    const handleProductPriceInput = (e) => {
        SetProductPrice(e.target.value);
    }
    useEffect(() => {
        if (productPrice) {
            sendPriceAndDiscountByJS(discountFormFieldJson, discountFormValuesJson, productPrice)
        } else {
            sendPriceAndDiscountByJS(discountFormFieldJson, discountFormValuesJson, 0);
        }
    }, [productPrice])
    
    //////////////////////////////////

	//////////// Handle Product Price Evolutel
	const handleProductPriceEvolutel = (e) => {
		setProductPriceEvolutel(e.target.value);
	}
	useEffect(() => {
        if (productPriceEvolutel) {
            sendPriceHtml(productPriceEvolutel, false, __js_splitCurrency(__js_priceConvertToFloat(productPriceEvolutel)));
        } else {
            sendPriceHtml(0, false, __js_splitCurrency(__js_priceConvertToFloat(0)));
        }
    }, [productPriceEvolutel])
	//////////////////////////////////

    //////////////////////// ADDITIONAL PRICE INFO /////////////////////////
    ////////////// Handle Extra Price Info
    const handleExtraPriceInfoChange = (e) => {
        setExtraPriceInfo(e.target.value);
    }

    useEffect(() => {
        //EXTRA PRICE INFO
        sendAdditionalPriceMechanicData();
    }, [extraPriceInfo]);
    //////////////////////////////////////

    ////////////// Handle Pack Size 
    const handlePackSizeChange = (e) => {
        setPackSize(e.target.value);
    }

    useEffect(() => {
        //PACK SIZE
        sendAdditionalPriceMechanicData();
    }, [packSize]);
    /////////////////////////////////////////

	////////////// Handle Volume/Contenance On Change
	const handleVolumeOnChange = (volumeValue, volumePart) => {
		switch (volumePart) {
			case 'value':
				setVolume(prevState => ({ ...prevState, value: volumeValue }));
				break;
			case 'unit':
				setVolume(prevState => ({ ...prevState, unit: volumeValue }));
                break;
			default:
				break;
		}
	}

	useEffect(() => {
		//volume
        if (volume) {
            const value = volume?.value;
            const unitValue = volume?.unit?.value;
            if (value === "1") {
				switch (unitValue) {
					case "KG":
						setVolumeText("Le KG");
						break;
					case "L":
						setVolumeText("La Litre");
						break;
					case "g":
						setVolumeText("Le gramme");
						break;
					default:
						setVolumeText("L'Unité");
						break;
				}				
            } else {
                if (!isInitialLoad) setVolumeText(`${value ?? ''} ${unitValue ?? ''}`);
            }
        }
        sendAdditionalPriceMechanicData();
	}, [volume])
    ////////////////////////////////////////////////////////////////////

    //////////////// Handle Volume Text On Change
    const handleVolumeTextChange = (e) => {
        setVolumeText(e.target.value);
    }
    useEffect(() => {
        sendAdditionalPriceMechanicData();
    }, [volumeText])
    ///////////////////////////////////////////////////////////////////

    //////////////// Handle Hide Volume Text On Change
    const handleHideVolumeTextChange = (e) => {
        setHideVolumeText(e);
    }
    useEffect(() => {
        sendAdditionalPriceMechanicData();
    }, [hideVolumeText])
    ///////////////////////////////////////////////////////////////////
    //////////////////////// ## ADDITIONAL PRICE INFO ## /////////////////////////

    /////// HANDLE DISCOUNTS THAT NEED DATETIME FIELDS //////////
    useEffect(() => {
        if (showDiscountDateTimeFields) {
            // populate form field json here
            const dateTimeFormFields = {
                "standard_custom_date_time" : [{
                    "id": 0,
                    "name": "start_data",
                    "type": "datetime",
                    "title": "Start Date",
                    "groupKey": null,
                    "sortOrder": 1,
                    "configValues": {
                        "values": {
                        "required": "0"
                        },
                        "frontUniqueKeys": {
                        "datetime1": "datetime_datetime_1"
                        }
                    },
                    "translatedConfigValues": {
                        "values": {
                        "required": "0"
                        },
                        "frontUniqueKeys": {
                        "datetime1": "datetime_datetime_1"
                        }
                    }
                    },
                    {
                    "id": 1,
                    "name": "end_date",
                    "type": "datetime",
                    "title": "End Date",
                    "groupKey": null,
                    "sortOrder": 2,
                    "configValues": {
                        "frontUniqueKeys": {
                        "datetime1": "datetime_datetime_1"
                        }
                    },
                    "translatedConfigValues": {
                        "frontUniqueKeys": {
                        "datetime1": "datetime_datetime_1"
                        }
                    }
                    }
                ]
            }

            if (discountGroupedFormFieldJson) {
                setDiscountGroupedFormFieldJson(
                    {
                        ...discountGroupedFormFieldJson,
                        ...dateTimeFormFields
                    }
                )
            } else {
                setDiscountGroupedFormFieldJson(dateTimeFormFields);
            }

        } else {
            // remove from form field json here
            setDiscountGroupedFormFieldJson(prevDiscountGroupedFormFieldJson => {
                const newDiscountGroupedFormFieldJson = {...prevDiscountGroupedFormFieldJson};
                if ("standard_custom_date_time" in newDiscountGroupedFormFieldJson) {
                    delete newDiscountGroupedFormFieldJson["standard_custom_date_time"];
                }
                return newDiscountGroupedFormFieldJson;
            });
        }
    }, [showDiscountDateTimeFields]);
    //////////////////////////////////////////////////////////////


	
    //////////////Handle Chartes Graphiques on Change
    const handleSelectCgOnChange = (selectedOption) => {
        setSelectedOptionChartesGraphique(selectedOption);
    }
    //////////////////////////////////

    //////////////Handle Discount On Change
    const handleSelectDiscountOnChange = (selectedOption) => {
        setSelectedOptionDiscount(selectedOption); // Update the state with the selected option
    }
    //////////////////////////////////

    ////////////////////Handle Template Extension on change
    const handleSelectTemplateExtensionOnChange = (selectedOption) => {
        setSelectedOptionTemplateExtension(selectedOption);
    }
    ////////////////////////////////////////////////////////

	////////////// Handle Format Option On Change
	const handleSelectFormatOnChange = (selectedOption) => {
		setSelectedFormatOption(selectedOption);
	}
	////////////////////////////////////////////////////////

	/////////// Handle Orientation Option on Change
	const handleSelectOrientationOnChange = (selectedOption) => {
		setSelectedOrientationOption(selectedOption);
	}

    ////////////////////Handle Product Image Uploaded
    const handleProductImageUploaded = (imagesUploaded) => {
        if (imagesUploaded)
        {
            setUploadedProductImage(imagesUploaded[0].uploadedInfo);
        } else {
            setUploadedProductImage(null);
        }
        
    }
    //////////////////////////////////

    ////////////////////Handle Product Image Uploaded
    const handlePriceImageUploaded = (imagesUploaded) => {
        if (imagesUploaded)
        {
            setUploadedPriceImage(imagesUploaded[0].uploadedInfo);
        } else {
            setUploadedPriceImage(null);
        }
        
    }
    //////////////////////////////////

    /////////////// Handle On Date Change ///////////////
    const handleOnDateChange = (dateValue, name) => {
        switch (name) {
            case 'select-start-date':
                setInfoDates(prevDates => ({
                    ...prevDates,
                    startDate: dateValue
                }));
                break;
            case 'select-end-date':
                setInfoDates(prevDates => ({
                    ...prevDates,
                    endDate: dateValue
                }));
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!isInitialLoad) {
            sendInfoDates(infoDates);
        }
    }, [infoDates])
    ///////////////////////////////////////////////////

    const updateData = (prd = [], tplGroup = [], tplExt = [], discount = [], legalMention = [], images = []) => {
        const updatedData = getDataJson(prd, tplGroup, tplExt, discount, legalMention, images);

        function base64url_encode(data) {
            return data
                .replaceAll(/\+/g, '-')
                .replaceAll(/\//g, '_')
                .replaceAll(/=/g, '');
        }
        // using this function to make the base64 encoded data url safe, because the characters in normal b64 might break the url ( + , /, =)
        const updateDataBase64 = Base64.encode(JSON.stringify(updatedData));

        SetDataJson(base64url_encode(updateDataBase64));
    };

    const updateProductData = () => {
        const prodData = {
            'productName' : productName,
            'productDescription' : productDescription,
            'productPrice' : productPrice,
            'volumeText': volumeText,
            'volumeData': volume,
            'packSize': packSize,
            'hideLibelleVolume': hideVolumeText,
            'extraPriceInfo': extraPriceInfo,
        }
        setProductData(prodData);
    }

    const updateImagesData = () => {
        const imagesData = {
            'productImage' : uploadedProductImage,
            'priceImage' : uploadedPriceImage
        }
        setImagesData(imagesData);
    }

    const updateDiscountData = () => {
        const discountData = {
            "discountId": selectedOptionDiscount ? selectedOptionDiscount.id : (originalDiscountOption ? originalDiscountOption.id : ""),
            "discountFormValuesJson" : discountFormValuesJson,
        }
        setDiscountData(discountData);
    }

    const updateTemplateExtensionData = () => {
        const templateExtensionData = {
            'templateExtensionId' : selectedOptionTemplateExtension ? selectedOptionTemplateExtension.value : null,
            'templateExtensionFormValuesJson' : templateExtensionFormValuesJson
        }
        setTemplateExtensionData(templateExtensionData);
    }

    const updateTemplateGroupData = () => {
        const tplGroupData = {
            "id" : selectedOptionChartesGraphique ? selectedOptionChartesGraphique.id : null
        }
        setTplGroupData(tplGroupData);
    }

    const getGencodeData = (data) => {
        //get selected productLocal object
        setGencodeData(data);
    }

    useEffect(() => {
        if (gencodeData) {
            let productLocalName = null
            let productLocalDescription = null;
            if (gencodeData?.commercial_name && gencodeData.commercial_name !== "") {
                productLocalName = gencodeData.commercial_name;
            }
            if (!productLocalName) productLocalName = gencodeData?.name ?? ""
            if (gencodeData?.commercial_description && gencodeData.commercial_description !== "") {
                productLocalDescription = gencodeData.commercial_description;
            }
            const productLocalPrice = gencodeData?.commercialPrice ?? gencodeData?.price ?? null;
            //do logic to set gencode data TODO_NW -- RMT
            SetProductName(productLocalName);
            SetProductDescription(productLocalDescription);
            SetProductPrice(productLocalPrice);
            setProductPriceEvolutel(gencodeData?.price);

            let imageInfo = {
                imageCdnUrl : "",
                imageOutputPath : ""
            };
            if (gencodeData?.productAssetsData) {
                const productAssetsData = gencodeData.productAssetsData;
                imageInfo = {
                    imageCdnUrl: productAssetsData?.productImage?.images?.mainImage?.cdnUrl,
                    imageOutputPath: productAssetsData?.productImage?.images?.mainImage?.completePath
                }
                setUploadedProductImage(imageInfo);
                setOriginalUploadedProductImage([imageInfo]);
            }

			if (gencodeData?.barcodeGenerated) {
				const barCodeHTML = gencodeData?.barcodeGenerated?.HTML;
				sendGenCodeBarcode(barCodeHTML, gencodeData?.ean);
			}

            setEanValue(gencodeData?.ean);
        }
    }, [gencodeData]);

	/////////////// LEGAL MENTIONS ///////////////
	//handle addtional legal mentions
	const handleAdditionalLegalMentions = (e) => {
		setAdditionalLegalMentions(e.target.value);
	}

	useEffect(() => {
		if (selectedLegalMentions){
            const legals = selectedLegalMentions?.legals ?? [];
            legals.sort((item1, item2) => {
                if (('position' in item1) && ('position' in item2)) return parseInt(item1.position) - (item2.position)
                else return 0;
            })
			sendLegalMentions(legals, additionalLegalMentions);
		}
	}, [selectedLegalMentions])

	useEffect(() => {
		sendLegalMentions(selectedLegalMentions?.legals ?? [], additionalLegalMentions ?? '');
	}, [additionalLegalMentions])
	
	////////////////////////////////////////


    ///////////////////// DOWNLOADS HANDLING //////////////////////

    useEffect(() => {
        const totalIframes = Object.keys(hiddenPostTypeIframeRefs).length;
        const downloadLength = Object.keys(downloadData).length
        if (downloadLength === 0) return;
        if (downloadLength === totalIframes) {
            // Set shouldStartDownload to true to trigger the download process
            setShouldStartDownload(true);

        }
    }, [downloadData]);

    useEffect(() => {
        if (shouldStartDownload) {
            async function downloadZip() {
                // Perform the download logic here
                const response = await downloadSocialMediaPosterPack(downloadData);
                const url = URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;

                a.download = "media.zip";
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(url);

                setDownloadData({});

                // Reset the shouldStartDownload state
                setShouldStartDownload(false);
                setIsDownloading(false);
            }
            
            downloadZip()
        }
    }, [shouldStartDownload]);
    ///////////////////////////////////////////////////////////////


    /////////// UTILS FUNCTIONS////////////////

    //Handle Formatting for Format Preview, also used to assign aspectRatio to iframe element
    const handleFormatPreviewOrientation = () => {
        if (selectedFormatOption && selectedOrientationOption){
            return formatLabelforFormatPreview(selectedOrientationOption.value, selectedFormatOption.size);
        }
    }

    const handleBackButton = () => {
        if (window.history.length > 2) {
            // Go back if there is a previous page in history
            navigate(-1);
        } else {
            // Fallback to a default route if there's no history
            navigate('/social-media');
        }
    };
    ////////////////////////////////////////////////

    const getSocialMediaProductDataForApi = () => {
        const socialMediaProductDetails = {};
        socialMediaProductDetails['socialMediaProductId'] = productId;
        socialMediaProductDetails['action'] = onSaveAction;
        
        const socialMediaProductData = {
            'ean' : eanValue,
            'productDetails' : {
                'productName' : productName,
                'productDescription' : productDescription,
                'productPrice' : productPrice,
                'extraPriceInfo' : extraPriceInfo,
                'packSize' : packSize,
                'volumeData' : volume,
                'volumeText' : volumeText
            },
            'images' : {
                'productImage' : uploadedProductImage,
                'priceImage' : uploadedPriceImage
            },
            'discountData' : {
                'discountId' : selectedOptionDiscount ? selectedOptionDiscount.id : null,
                'discountFormValuesJson' : discountFormValuesJson
            },
            'templateExtensionData' : {
                'templateExtensionId' : selectedOptionTemplateExtension ? selectedOptionTemplateExtension.value : null,
                'templateExtensionFormValuesJson' : templateExtensionFormValuesJson
            },
            'postTypesJson' : socialMediasData,
            'legalMentions' : selectedLegalMentions,
			'additionalLegalMentions' : additionalLegalMentions,
            "templateGroupId" : currentSelectedTemplateGroup ? currentSelectedTemplateGroup.id : null,
            'customPosterData' : {
                "formatId": selectedFormatOption?.value,
                "orientation": selectedOrientationOption?.value,
                'startDate': convertDate(infoDates.startDate),
                'endDate': convertDate(infoDates.endDate),
                'showHeaderName': showHeaderName,
				'showHeaderDate': showHeaderDate,
                'showProductImage': showImageProduct,
                'hideVolumeText' : hideVolumeText,
            },
            "otherDataJson" : dataJson,
            "packGroupId" : savePackGroup?.value ?? 0,
            "newPackGroupName" : newPackGroupName,
            'covers' : templateGroupCovers,
        }

        socialMediaProductDetails['productData'] = socialMediaProductData;

        return socialMediaProductDetails;
    }

    const handleIframeDownloadData = (dataUrl, postTypeId) => {
        setDownloadData(prevDownloadData => {
            const newDownloadData = {...prevDownloadData}
            newDownloadData[postTypeId] = dataUrl;
            return newDownloadData;
        });
    }

    const handleDownloadSocialMediaPosters = async () => {
        const socialMediaProductDetails = getSocialMediaProductDataForApi();
        socialMediaProductDetails['action'] = "DUPLICATE";
        const response = downloadSocialMediaPosterPackByQueue(socialMediaProductDetails);

        if (response) {
            triggerToast(null, {
                title: t('editorSocialMedia.alerts.zip_generation_in_progress'),
                type: "info",
                closeButton: true,
            })
        } else {
            triggerToast(null, {
                title: t('editorSocialMedia.alerts.zip_generation_error'),
                type: "danger",
                closeButton: true,
            })
        }
		return;
    }

    const handleSaveSocialMediaProduct = async (addToCart = true) => {
        setIsSaving(true);
        setCloseModal(!closeModal)
        
        if (addToCart)
        {
            setIsAddingToCart(true);
        }

        const socialMediaProductDetails = getSocialMediaProductDataForApi();
        socialMediaProductDetails['addToCart'] = addToCart;

        // validate form values to restrict save
        // const isTemplateExtensionFormValuesValid = validateFormValuesByFormFieldJson(templateExtensionFormFieldJson, templateExtensionFormValuesJson);
        const isDiscountFormValuesValid = validateFormValuesByFormFieldJson(discountFormFieldJson, discountFormValuesJson);
        const formValuesValid = isDiscountFormValuesValid;
        // const formValuesValid = isTemplateExtensionFormValuesValid && isDiscountFormValuesValid;
        
        if(formValuesValid) {
            const response = await saveSocialMediaProduct(socialMediaProductDetails)

            if (response) {
                triggerToast(null, {
                    "type": "success",
                    "title": t('postersPage.alerts.poster_save_success'),
                    "closeButton" : true
                });
            } else {
                triggerToast(null, {
                    "type": "error",
                    "title": t('postersPage.alerts.poster_save_error'),
                    "closeButton" : true
                });
            }
        } else {
            const response = await saveSocialMediaProduct(socialMediaProductDetails)

            if (response) {
                triggerToast(null, {
                    "type": "success",
                    "title": t('postersPage.alerts.poster_save_success'),
                    "closeButton" : true
                });
            } else {
                triggerToast(null, {
                    "type": "error",
                    "title": t('postersPage.alerts.poster_save_error'),
                    "closeButton" : true
                });
            }
        }

        setIsSaving(false);
        setIsAddingToCart(false);
    }

    // Function for iframe reload
    const reloadPosterIframe = () => {
        Object.values(postTypeIframeRefs).forEach(iframeRef => {
            iframeRef.current.src = iframeRef.current.src;
        })
        
    };

  return (
    <div className="social-media-editor nw-poster-main-wrapper">
        <div className="d-flex flex-column w-100 h-100 position-relative">
            <div className="nw-poster-header-container d-flex justify-content-between align-items-center py-3 px-4 ">
                <div className="nw-poster-back-btn-container">
                    <Button className="nw-poster-back-button btn-primary rounded-1 header-btn" onClick={handleBackButton}>
                        <span><ArrowLeft className="me-1"/>{ t('backButtonLabel') }</span>
                    </Button>
                </div>
                <div className="nw-poster-buttons-container d-flex column-gap-4">
                    <NwCustomModal 
                        title={t('editor.inputs.save')}
                        closeModal={closeModal}
                        customButton={
                            <Button
                                className={`px-4 rounded-1 me-3 w-100 text-nowrap ${isSaving ? 'd-flex column-gap-1 align-items-center' : ''}`}
                                disabled={isSaving}
                                style={{
                                    fontFamily: "Montserrat-SemiBold",
                                    fontSize: "var(--nw-responsive-font-size)",
                                    backgroundColor: "var(--nw-primary-color)",
                                    height: "fit-content",
                                    paddingBlock: "var(--nw-responsive-padding)"
                                }}
                            >{t('freeEditorSocialMedia.common.save')}{ isSaving && <NwLoaderSpinner isSpinner size={'1em'}/> }</Button>
                        }
                        modalProps={{
                            closeBtn: {
                                className: 'bg-transparent text-white border-0',
                                iconSize: 20
                            }
                        }}
                    >
                        <div className="d-flex flex-column align-items-center px-4 pb-3" style={{width: '35vw', height: "fit-content"}}>
                            <span 
                                className="nw-text-center nw-font-open-sans fw-bold"
                                style={{
                                    fontSize: 'clamp(14px, 0.9vw, 23px)'
                                }}
                            >
                                <Trans
                                    i18nKey={'freeEditorSocialMedia.common.save_modal_header'}
                                />
                            </span>
                            <div className="save-modal-inputs-container w-100 mt-4 mb-4">
                                <div className="w-100 nw-font-open-sans">
                                    <div className="mb-2">{ t('freeEditorSocialMedia.common.addToExistingCampaign') }</div>
                                    <NwCustomSelect
                                        onChange={(params) => {setSavePackGroup(params)}} 
                                        value={savePackGroup} 
                                        options={packGroupOptions}
                                        placeholder={ t('freeEditorSocialMedia.common.chooseCampaignPlaceholder') }
                                        label={ t('freeEditorSocialMedia.common.chooseCampaignPlaceholder') }
                                        menuPortalTarget={null}
                                        uiOverrides={{
                                            background: {
                                                control: '#F2F2F2'
                                            },
                                            menuList: {
                                                maxHeight: '10vh'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-100 nw-font-open-sans mt-2">
                                    <NwCustomInput
                                        type="text"
                                        label={t('freeEditorSocialMedia.common.addToNewCampaign')}
                                        labelClassName="text-black"
                                        placeholder={t('freeEditorSocialMedia.common.newCampaignNamePlaceholder')}
                                        value={newPackGroupName}
                                        inputWrapperClassName="nw-bg-tertiary-color"
                                        inputClassName="nw-bg-tertiary-color"
                                        onChange={(e)=>{setNewPackGroupName(e.target.value)}}													
                                    />
                                </div>
                            </div>

                            <Button 
                                onClick={() => handleSaveSocialMediaProduct(false)}
                                disabled={isInitialLoad || isSaving}
                                className="px-5 rounded-1 d-flex justify-content-center align-items-center column-gap-2"
                                style={{
                                    fontFamily: "Montserrat-SemiBold",
                                    fontSize: "var(--nw-responsive-font-size)",
                                    paddingBlock: "var(--nw-responsive-padding-lg)",
                                    height: "fit-content"
                                }}
                            >
                                {t('freeEditorSocialMedia.common.save_modal_text')}{ isSaving && <NwLoaderSpinner isSpinner size={15}/> }
                            </Button>
                        </div>
                    </NwCustomModal>
                    
                    <Button className="nw-poster-download-btn rounded-1 border-0 header-btn" onClick={handleDownloadSocialMediaPosters} disabled={isInitialLoad || isSaving}>
                        <span className="me-2">{ t('editor.inputs.download') }</span>
                        <span>
                            {isDownloading && <NwLoaderSpinner isSpinner size={20}/>}
                        </span>
                    </Button>
                </div>
            </div>
            <div className="nw-poster-body d-flex w-100 h-100">
                <div className='w-75 d-flex flex-column'>
                    <Form className="h-100">
                        <Tab.Container id="edit-poster-tab-container" defaultActiveKey="#formats">
                            <Row className="gx-0 h-100">
                                <Col xs={4} className="tab-items-container">
                                    <ListGroup variant="flush">
                                        <EditTabItem tabIcon={<Window size={20} />} tabTitle={ t('editorSocialMedia.tabs.platform.title') } href="#formats" />
                                        <EditTabItem tabIcon={<LayoutTextWindowReverse size={20} />} tabTitle={ t('postersPage.newPosterPage.templatesGroups') } href="#chartes-graphiques" />
                                        <EditTabItem tabIcon={<TextesLogo size={20} />} tabTitle={ t('postersPage.newPosterPage.texts') } href="#edit" />
                                        <EditTabItem tabIcon={<Coin size={20} />} tabTitle={ t('postersPage.newPosterPage.priceBlock') } href="#priceMechanic-and-template" />
                                        <EditTabItem tabIcon={<Image size={20} />} tabTitle={ t('postersPage.newPosterPage.images') }  href="#images" />
                                        <EditTabItem tabIcon={<LegalMentionsLogo size={20} />} tabTitle={ t('postersPage.newPosterPage.legalMentions') } href="#legal-mentions" />
                                        <EditTabItem tabIcon={<NwSocialMediaCoverTab size={20} />} tabTitle={ t('postersPage.newPosterPage.cover') } href="#covers" />
                                    </ListGroup>
                                </Col>
                                <Col className="tab-pane-container">
                                
                                    <Tab.Content className="px-5 pb-4" style={{
                                        minHeight: '60vh'
                                    }}>
                                        {
                                            templateExtensionNotice && 
                                            <Alert key={"danger"} variant={"danger"}>
                                                {templateExtensionNotice}
                                            </Alert>
                                        }
                                        {/* SOCIAL MEDIA - PLATFORM & FORMATS TAB */}
                                        <Tab.Pane className="text-white nw-tab-pane " eventKey="#formats">
											<div className="text-white nw-responsive-font-size mb-4">{ t('editorSocialMedia.tabs.platform.headerTitle') }</div>                                   
                                            <PlatformFormatTab 
                                                updatePreviewContainerActive={setPreviewContainerCurrentActive} 
                                                currentActiveSocialMediaTab={ previewContainerCurrentActive } 
                                                updateSocialMediaData={setSocialMediasData} 
                                                socialMedias={socialMediasData}
                                            />
                                        </Tab.Pane>
                                        {/* INFO GENERAL - INFO TAB */}
                                        <Tab.Pane className="text-white nw-tab-pane " eventKey="#info">                                            
                                            <Form.Group className="mb-3" controlId="select-format">
                                                <Form.Label>{ t('postersPage.newPosterPage.format') }</Form.Label>
                                                <NwCustomSelect
                                                    options={formatOptions}
                                                    isDisabled={isFormatLoading || isInitialLoad || iframeIsLoading}
                                                    className="text-black"
                                                    onChange={handleSelectFormatOnChange}
                                                    value={selectedFormatOption}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="select-orientation">
                                                <Form.Label>{ t('postersPage.newPosterPage.orientation') }</Form.Label>
                                                <NwCustomSelect
                                                    options={orientationOptions}
                                                    isDisabled={isOrientationLoading || isInitialLoad || iframeIsLoading}
                                                    className="text-black"
                                                    onChange={handleSelectOrientationOnChange}
                                                    value={selectedOrientationOption}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="select-start-date">
                                                <Form.Label>{ t('postersPage.newPosterPage.startDate') }</Form.Label>
                                                <NwCustomDatePicker initialDate={infoDates.startDate} disabled={isInitialLoad || iframeIsLoading} name="select-start-date" onDateChange={handleOnDateChange} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="select-end-date">
                                                <Form.Label>{ t('postersPage.newPosterPage.endDate') }</Form.Label>
                                                <NwCustomDatePicker initialDate={infoDates.endDate} disabled={isInitialLoad || iframeIsLoading} name="select-end-date" onDateChange={handleOnDateChange}/>
                                            </Form.Group>
                                            <div className={`nw-format-preview-container d-flex justify-content-center w-100 ${selectedFormatOption && selectedOrientationOption ? '' : 'd-none'}`}>
                                                <div className="nw-format-preview-main-wrapper">
                                                    <div className={`nw-format-preview-elem w-100 h-100 ${selectedOrientationOption?.value == 'landscape' ? 'landscape' : ''}`}>
                                                        <div className="nw-format-preview-elem-width-label">{orientationOptions && handleFormatPreviewOrientation()?.width}</div>
                                                        <div className="w-100 h-100 px-1 nw-format-preview-icon-container">
                                                            <FileEarmarkFill className={`nw-format-preview-icon ${selectedOrientationOption?.value == 'landscape' ? 'landscape' : ''}`}/>
                                                        </div>
                                                        <div className="nw-format-preview-elem-height-label d-flex align-items-center">{orientationOptions && handleFormatPreviewOrientation()?.height}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* INFOS PRODUIT - PRODUCT INFO TAB */}
                                        <Tab.Pane className="text-white nw-tab-pane " eventKey="#edit">
                                            <NameWithSearch disabled={isInitialLoad || iframeIsLoading} setEanValue={setEanValue} productName={productName} handleProductNameInput={handleProductNameInput} getGencodeData={getGencodeData}/>
                                            <Gencode eanValue={eanValue} disabled={isInitialLoad || iframeIsLoading} setEanValue={setEanValue} getGencodeData={getGencodeData}/>
                                            <Form.Label className="d-none">{ t('postersPage.newPosterPage.showHeader.title') }: </Form.Label>
                                            <div className="header-display-options-container d-flex d-none">
                                                <NwCustomCheckbox 
                                                    initialIsCheck={originalShowHeaderName.value}
                                                    isDisabled={isInitialLoad || iframeIsLoading}
                                                    checkboxSize="nw-md"
                                                    variant="primary"
                                                    labelClassName="text-white" label={t('postersPage.newPosterPage.showHeader.name')}
                                                    OnChange={(value) => {handleOnShowHeaderChange('name', value, setShowHeaderName)}}
                                                />
                                                <NwCustomCheckbox 
                                                    initialIsCheck={originalShowHeaderDate.value}
                                                    isDisabled={isInitialLoad || iframeIsLoading}
                                                    checkboxSize="nw-md"
                                                    variant="primary"
                                                    labelClassName="text-white" label={t('postersPage.newPosterPage.showHeader.date')}
                                                    OnChange={(value) => {handleOnShowHeaderChange('date', value, setShowHeaderDate)}}
                                                />
                                            </div>
                                            <div>
                                                <div className="d-flex column-gap-5">
                                                    <NwCustomInput 
                                                        label={ t('postersPage.newPosterPage.productprice') }
                                                        className="mb-3 mt-3"
                                                        type="number"
                                                        controlId="priceFormElem"
                                                        placeholder={ t('postersPage.newPosterPage.productprice') }
                                                        disabled={isInitialLoad || iframeIsLoading}
                                                        value={productPrice}
                                                        onChange={handleProductPriceInput}
                                                    />
                                                    <NwCustomInput 
                                                        label={ t('postersPage.newPosterPage.priceEvolutel') }
                                                        className="mb-3 mt-3"
                                                        type="number"
                                                        controlId="priceEvolutelFormElem"
                                                        placeholder={ t('postersPage.newPosterPage.priceEvolutel') }
                                                        disabled={true}
                                                        value={productPriceEvolutel}
                                                        onChange={handleProductPriceEvolutel}
                                                    />
                                                </div>
                                                <NwCustomInput className="d-none" disabled={isInitialLoad || iframeIsLoading} label={t('postersPage.newPosterPage.extraPriceInfo')} onChange={handleExtraPriceInfoChange} value={extraPriceInfo} placeholder={extraPriceInfo} />
                                                <NwCustomInput disabled={isInitialLoad || iframeIsLoading} label={t('postersPage.newPosterPage.packSize')} type="number" onChange={handlePackSizeChange} value={packSize} placeholder={packSize}/>
                                            </div>
                                            <NwCustomInput 
                                                label={ t('postersPage.newPosterPage.productDescription') }
                                                noBar
                                                className="mb-3"
                                                as="textarea"
                                                style={{ height: '20vh' }}
                                                controlId="descriptionFormElem"
                                                placeholder={ t('postersPage.newPosterPage.productDescription') }
                                                disabled={isInitialLoad || iframeIsLoading}
                                                value={productDescription} 
                                                onChange={handleProductDescriptionInput}
                                            />
                                            <div className="d-flex column-gap-5">
                                                <div className="w-50">
                                                    <NwCustomInput 
                                                        label={ t('postersPage.newPosterPage.volume.value') }
                                                        className="mb-3 mt-3"
                                                        type="number"
                                                        controlId="volumeValueFormElem"
                                                        disabled={isInitialLoad || iframeIsLoading}
                                                        placeholder={ t('postersPage.newPosterPage.volume.value') }
                                                        value={volume?.value}
                                                        onChange={(e) => { handleVolumeOnChange(e.target.value, 'value'); }}
                                                    />
                                                </div>
                                                <div className="w-50 d-flex flex-column justify-content-end">
                                                    <Form.Label className="mt-3">{ t('postersPage.newPosterPage.volume.unit') }</Form.Label>
                                                    <NwCustomSelect
                                                        options={volumeUnitOptions}
                                                        isClearable
                                                        isDisabled={isInitialLoad || iframeIsLoading}
                                                        sm
                                                        uiOverrides={{
                                                            width: {
                                                                value: '50%',
                                                                dropdown: '50%',
                                                                dopdownButton: '3rem'
                                                            },
                                                            displaySpacing: {
                                                                value: '.5rem',
                                                                valueContainer: '1rem'
                                                            }
                                                        }}
                                                        className="text-black mb-3"
                                                        onChange={(selectedOption) => {handleVolumeOnChange(selectedOption, 'unit')}}
                                                        value={volume?.unit}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <NwCustomInput disabled={isInitialLoad || iframeIsLoading} label={t('postersPage.newPosterPage.volumeText')} value={volumeText} onChange={handleVolumeTextChange} placeholder={volumeText} />
                                                <div className="mt-2">
                                                    <NwCustomCheckbox isDisabled={isInitialLoad || iframeIsLoading} label={t('postersPage.newPosterPage.hideVolumeText')} variant="primary" initialIsCheck={hideVolumeText} OnChange={handleHideVolumeTextChange}/>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* CHARTES GRAPHIQUES - CHARTES GRAPHIQUE TAB */}
                                        <Tab.Pane className="nw-tab-pane" eventKey="#chartes-graphiques">
                                            <Form.Group className="mb-3"  controlId="select-chartes-graphiques">
                                                <TemplateGroupModels />
                                            </Form.Group>
                                        </Tab.Pane>
                                        {/* CHAMPS SPECIAUX - TEMPLATE EXTENSION TAB */}
                                        <Tab.Pane eventKey="#special-fields" className="text-white nw-tab-pane ">
                                            <Form.Group className="mb-3" controlId="select-template-extension">
                                                <Form.Label>{ t('postersPage.newPosterPage.templateExtension') }</Form.Label>
                                                <NwCustomSelect
                                                    options={templateExtensionOptions}
                                                    isDisabled={isTemplateExtensionLoading || isInitialLoad || iframeIsLoading}
                                                    isClearable
                                                    className="text-black"
                                                    onChange={handleSelectTemplateExtensionOnChange}
                                                    value={selectedOptionTemplateExtension}
                                                />
                                                <FormFieldContainer isDisabled={isInitialLoad || iframeIsLoading} productId={productId} updateEntityFormValuesJson={setTemplateExtensionFormValuesJson} entityFormFieldsJson={templateExtensionGroupedFormFieldJson} entityFormValuesJson={templateExtensionFormValuesJson} />
                                            </Form.Group>
                                        </Tab.Pane>
                                        {/* BLOCS PRIX - PRICE MECHANIC TAB */}
                                        <Tab.Pane className="text-white nw-tab-pane " eventKey="#priceMechanic-and-template">
                                            <Form.Group className="mb-3" controlId="select-discount">
                                                <Form.Label>{ t('postersPage.newPosterPage.discount') }</Form.Label>
                                                <PreviewList
                                                    initialSelection={originalDiscountOption}
                                                    listData={discountOptions}
                                                    getListData={getSocialMediaDiscounts}
                                                    isDisabled={isInitialLoad || iframeIsLoading}
                                                    updateListData={setDiscountOptions}
                                                    getCurrentSelected={handleSelectDiscountOnChange}
                                                    fetchSearchData={handleSearchData}
                                                    fetchMoreData={handleFetchMoreData}
                                                    bodyData={discountBodyData}
                                                    bodyDataUpdator={setDiscountBodyData}
                                                    hideMoreButton={usingTemplateExtensionDiscounts}
                                                    allowDeselection={true}
                                                />
                                                <FormFieldContainer isDisabled={isInitialLoad || iframeIsLoading} productId={productId} updateEntityFormValuesJson={setDiscountFormValuesJson} entityFormFieldsJson={discountGroupedFormFieldJson} entityFormValuesJson={discountFormValuesJson} />
                                            </Form.Group>
                                            <NwCustomImageDropzone disabled={isInitialLoad || iframeIsLoading} initialUploadedInfos={originalUploadedPriceImage} maxFiles={1} type="price_image" handleFilesUploaded={handlePriceImageUploaded}/>
                                        </Tab.Pane>
                                        {/* MENTION LEGALE - LEGAL MENTIONS TAB */}
                                        <Tab.Pane className="nw-tab-pane " eventKey="#legal-mentions">
                                            <Form.Label className="text-white">{ t('postersPage.newPosterPage.legalMentions') }</Form.Label>
                                            <LegalMentions isDisabled={isInitialLoad || iframeIsLoading} templateExtensionLegalMentions={templateExtensionLegalMentions} discountLegalMentions={discountLegalMentions} updateSelectedLegalMentions={setSelectedLegalMentions} selectedLegalMentions={selectedLegalMentions} productId={productId} />
                                            <NwCustomInput 
                                                label={ t('postersPage.newPosterPage.additionallegalMentions') }
                                                labelClassName="text-white"
                                                noBar
                                                className="mt-3"
                                                disabled={isInitialLoad || iframeIsLoading}
                                                as="textarea"
                                                style={{ height: '10vh' }}
                                                controlId="additionalLegalMentions"
                                                placeholder={ t('postersPage.newPosterPage.additionallegalMentions') }
                                                value={additionalLegalMentions}
                                                onChange={handleAdditionalLegalMentions}
                                            />
                                        </Tab.Pane>
                                        {/* IMAGE PRODUIT - IMAGE PRODUIT TAB */}
                                        <Tab.Pane className="nw-tab-pane " eventKey="#images">
                                            <NwCustomImageDropzone disabled={isInitialLoad || iframeIsLoading} initialUploadedInfos={originalUploadedProductImage} maxFiles={1} handleFilesUploaded={handleProductImageUploaded} allowDelete={false}/>
                                            <NwCustomCheckbox isDisabled={isInitialLoad || iframeIsLoading} variant="primary" initialIsCheck={showImageProductQuery} labelClassName="text-white" label={t('postersPage.newPosterPage.showImageProduct')} OnChange={setShowImageProduct}/>
                                        </Tab.Pane>
                                        {/* COVERS - COVERS TAB */}
                                        <Tab.Pane className="text-white nw-tab-pane " eventKey="#covers">
											<div className="text-white nw-responsive-font-size mb-4">{ t('editorSocialMedia.tabs.covers.headerTitle') }</div>
                                            {
                                                isEmptyObject(templateGroupCovers) === false ? (
                                                    <>
                                                        <div className="nw-theme-type-and-theme-wrapper d-flex mb-3 fw-semibold column-gap-1">
                                                            <div className="text-white nw-responsive-font-size">{ (currentSelectedThemeType?.label + ':' ?? '') }</div> 
                                                            <div className="text-white nw-responsive-font-size">{ (currentSelectedTemplateGroup?.label ?? '') }</div> 
                                                        </div>
                                                        <div className="w-100 border border-1 border-white mb-3"></div>
                                                        <span>{ t('editorSocialMedia.tabs.covers.chooseFormat') }</span>
                                                    </>
                                                ) : (
                                                    <span>{t('editorSocialMedia.tabs.covers.noTemplateGroupSelected')}</span>
                                                )
                                            }
                                            <CoverTab covers={templateGroupCovers} updateCovers={setTemplateGroupCovers} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Form>
                </div>
                <div 
                    className='w-100 h-100 position-relative overflow-hidden'
                >
                    {
                        getDebugMode() && (
                                <Button variant="primary" className="position-absolute rounded-circle" style={{top: '10px', right: '10px', aspectRatio: '1'}} onClick={() => reloadPosterIframe()}>
                                    <ArrowClockwise size={30}/>
                                </Button>
                        )
                    }

                    <div className="preview-container-wrapper w-100 h-100">
                        <div className="preview-container overflow-hidden w-100 h-100 d-flex flex-column row-gap-2 row-gap-xxl-5 pt-3 px-5"
                            style={{
                                paddingBlock: currentPlatform === "is-large-desktop-3xxl" ? '2%' : '1%'
                            }}
                        >
                            <>
                                <Tab.Container className="flex-column" variant="pills" defaultActiveKey={'all'} activeKey={previewContainerCurrentActive ?? 'all'}>
                                    <Nav variant="pills" className="d-flex column-gap-3 flex-row">
                                        <Nav.Item>
                                            <Nav.Link 
                                                className="border border-primary text-capitalize px-4 border-1 rounded-pill" 
                                                eventKey={'all'}
                                                onClick={() => setPreviewContainerCurrentActive('all')}
                                                style={{
                                                    fontFamily: "Montserrat-SemiBold",
                                                    fontSize: "var(--nw-responsive-font-size)",
                                                    paddingBlock: "var(--nw-responsive-padding)",
                                                    height: "fit-content"
                                                }}
                                            >
                                                {t('editorSocialMedia.posterTabs.all')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        {
                                            Object.values(socialMediasData).map(socialMedia => {
                                                return (
                                                    <Nav.Item key={socialMedia.id}>
                                                        <Nav.Link 
                                                            className="border border-primary text-capitalize px-4 border-1 rounded-pill" 
                                                            eventKey={socialMedia.id}
                                                            onClick={() => setPreviewContainerCurrentActive(socialMedia?.id)}
                                                            style={{
                                                                fontFamily: "Montserrat-SemiBold",
                                                                fontSize: "var(--nw-responsive-font-size)",
                                                                paddingBlock: "var(--nw-responsive-padding)",
                                                                height: "fit-content"
                                                            }}
                                                        >
                                                            {socialMedia?.name}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                    <Tab.Content className="position-relative w-100 h-100 d-flex flex-row-reverse overflow-auto">
                                        <div className="w-25 d-flex flex-column">
                                            {
                                                Object.keys(templateGroupCovers).map(key => {
                                                    const cover = templateGroupCovers[key];

                                                    if (cover?.selected) {
                                                        return (
                                                            <Col key={key}>
                                                                <img className="cover-image w-100" alt="poster cover" src={cover?.images?.thumbnailPreviewUrl} />
                                                            </Col>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })
                                            }
                                        </div>
                                        <Tab.Pane eventKey={'all'} className="w-75 h-100">
                                            <Row xs={2} className="w-100 mx-0 row-gap-2">
                                            {
                                                Object.values(socialMediasData).map(socialMedia => {
                                                    return Object.values(socialMedia.postTypes).map(postType => {
                                                        const size = postType.size;
                                                        const iframeCurrentAspectRatio = isEmptyObject(size) ? 1 : formatLabelforFormatPreview(size.width / size.height > 1 ? 'landscape' : 'portrait', size).aspectRatio;
                                                        return (
                                                            <Fragment key={postType.id}>
                                                                {
                                                                    postType.selected &&
                                                                    (
                                                                        <Col 
                                                                            key={postType.id}
                                                                            className="ps-0 pe-0 social-media-preview-column-wrapper"
                                                                        >
                                                                            <EditorIframePreview
                                                                                isLoading={iframeIsLoading}
                                                                                iframeSrc={getBackendUrl(`social_media/renderer_front/${postType?.id}/${0}/${userNw?.id}/${5}?hideProductImage=${showImageProductQuery === "false" ? 1 : 0}&showHeaderName=${originalShowHeaderName.value ? 1 : 0}&showHeaderDate=${originalShowHeaderDate.value ? 1 : 0}&debugMode=0${dataJson ? `&dataJson=${dataJson}` : ''}&useImageProxy=0`, false)}
                                                                                onIframeRef={(iframeRef) => handleIframeRef(iframeRef, postType.id)}
                                                                                onIframeLoaded={() => SetIframeIsLoading(false)}
                                                                                handleIframeMessageData={(data) => {return;}}
                                                                                iframeLoadDependencies={[!!userNw, !isInitialLoad]}
                                                                                showSpinner={false}
                                                                                imgOverlay={{
                                                                                    isHeaderOnPreview: postType?.otherConfiguration?.header?.isOverlay == true,
                                                                                    isFooterOnPreview: postType?.otherConfiguration?.footer?.isOverlay == true,
                                                                                    header:(
                                                                                        <img 
                                                                                            className={`w-100 object-fit-fill ${postType?.otherConfiguration?.header?.url ? '' : 'd-none'} ${postType?.otherConfiguration?.header?.isOverlay ? 'position-absolute top-0' : ''}`} 
                                                                                            src={postType?.otherConfiguration?.header?.url ?? ''}
                                                                                        />
                                                                                    ),
                                                                                    footer: (
                                                                                        <img 
                                                                                            className={`w-100 object-fit-fill ${postType?.otherConfiguration?.footer?.url ? '' : 'd-none'} ${postType?.otherConfiguration?.footer?.isOverlay ? 'position-absolute bottom-0' : ''}`}
                                                                                            src={postType?.otherConfiguration?.footer?.url ?? ''}
                                                                                        />
                                                                                    ),
                                                                                }}
                                                                                iframeSlotsProps={{
                                                                                    root: {
                                                                                        className: 'w-100 h-100'
                                                                                    },
                                                                                    container: {
                                                                                        style: {
                                                                                            paddingBlock: currentPlatform === "is-large-desktop-3xxl" ? '2%' : '1%'
                                                                                        }
                                                                                    },
                                                                                    preview: {
                                                                                        root: {
                                                                                            style: {
                                                                                                height: 'auto', 
                                                                                                width: iframeCurrentAspectRatio <= 1 ? '100%' : '100%',
                                                                                                justifyContent: 'flex-start'
                                                                                            },
                                                                                            className: 'mt-0'
                                                                                        },
                                                                                        spinner: {
                                                                                            className: `${isInitialLoad ? 'initial-loading' : 'text-primary'} ${selectedOrientationOption?.value}`
                                                                                        },
                                                                                        iframeWrapper: {
                                                                                            className: `${iframeCurrentAspectRatio < 1 ? 'w-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}`,
                                                                                            style: {
                                                                                                aspectRatio: iframeCurrentAspectRatio ?? 1
                                                                                            }
                                                                                        },
                                                                                        iframe: {
                                                                                            className: `${iframeCurrentAspectRatio < 1 ? 'w-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}`,
                                                                                            style:{
                                                                                                aspectRatio: iframeCurrentAspectRatio ?? 1
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div className="social-media-editor-preview-post-details w-100 d-flex flex-column align-items-center justify-content-center nw-responsive-font-size-sm">
                                                                                <span className="social-media-name">{ socialMedia?.name }</span>
                                                                                <span className="post-type-name-and-size">{ postType?.name } ({size?.width + ' x ' + size?.height})</span>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                })
                                            }
                                            </Row>
                                        </Tab.Pane>
                                        {
                                            Object.values(socialMediasData).map(socialMedia => {
                                                return (
                                                    <Tab.Pane key={socialMedia.id} eventKey={socialMedia.id} className="w-75 h-100">
                                                        <Row xs={2} className="w-100 mx-0 row-gap-3">
                                                        {
                                                            Object.values(socialMedia.postTypes).map(postType => {
                                                                const size = postType.size;
                                                                const iframeCurrentAspectRatio = isEmptyObject(size) ? 1 : formatLabelforFormatPreview(size.width / size.height > 1 ? 'landscape' : 'portrait', size).aspectRatio;
                                                                return (
                                                                    <Fragment key={postType.id}>
                                                                        {
                                                                            postType.selected &&
                                                                            (
                                                                                <Col 
                                                                                    key={postType.id}
                                                                                    className="pe-0 ps-0 social-media-preview-column-wrapper"
                                                                                >
                                                                                    <EditorIframePreview
                                                                                        isLoading={iframeIsLoading}
                                                                                        iframeSrc={getBackendUrl(`social_media/renderer_front/${postType?.id}/${0}/${userNw?.id}/${5}?hideProductImage=${showImageProductQuery === "false" ? 1 : 0}&showHeaderName=${originalShowHeaderName.value ? 1 : 0}&showHeaderDate=${originalShowHeaderDate.value ? 1 : 0}&debugMode=0${dataJson ? `&dataJson=${dataJson}` : ''}&useImageProxy=0`, false)}
                                                                                        onIframeRef={(iframeRef) => handleIframeRef(iframeRef, postType.id, true)}
                                                                                        onIframeLoaded={() => SetIframeIsLoading(false)}
                                                                                        handleIframeMessageData={(data) => {return;}}
                                                                                        iframeLoadDependencies={[!!userNw, !isInitialLoad]}
                                                                                        showSpinner={false}
                                                                                        imgOverlay={{
                                                                                            isHeaderOnPreview: postType?.otherConfiguration?.header?.isOverlay == true,
                                                                                            isFooterOnPreview: postType?.otherConfiguration?.footer?.isOverlay == true,
                                                                                            header:(
                                                                                                <img 
                                                                                                    className={`w-100 object-fit-fill ${postType?.otherConfiguration?.header?.url ? '' : 'd-none'} ${postType?.otherConfiguration?.header?.isOverlay ? 'position-absolute top-0' : iframeIsLoading ? 'position-absolute top-0' : ''}`} 
                                                                                                    src={postType?.otherConfiguration?.header?.url ?? ''}
                                                                                                />
                                                                                            ),
                                                                                            footer: (
                                                                                                <img 
                                                                                                    className={`w-100 object-fit-fill ${postType?.otherConfiguration?.footer?.url ? '' : 'd-none'} ${postType?.otherConfiguration?.footer?.isOverlay ? 'position-absolute bottom-0' : iframeIsLoading ? 'position-absolute bottom-0' : ''}`}
                                                                                                    src={postType?.otherConfiguration?.footer?.url ?? ''}
                                                                                                />
                                                                                            ),
                                                                                        }}
                                                                                        iframeSlotsProps={{
                                                                                            root: {
                                                                                                className: 'w-100 h-100'
                                                                                            },
                                                                                            container: {
                                                                                                style: {
                                                                                                    paddingBlock: currentPlatform === "is-large-desktop-3xxl" ? '2%' : '1%'
                                                                                                }
                                                                                            },
                                                                                            preview: {
                                                                                                root: {
                                                                                                    style: {
                                                                                                        height: 'auto', 
                                                                                                        width: iframeCurrentAspectRatio <= 1 ? '100%' : '100%',
                                                                                                        justifyContent: 'flex-start'
                                                                                                    },
                                                                                                    className: 'mt-0'
                                                                                                },
                                                                                                spinner: {
                                                                                                    className: `${isInitialLoad ? 'initial-loading' : 'text-primary'} ${selectedOrientationOption?.value}`
                                                                                                },
                                                                                                iframeWrapper: {
                                                                                                    className: `${iframeCurrentAspectRatio < 1 ? 'w-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}`,
                                                                                                    style: {
                                                                                                        aspectRatio: iframeCurrentAspectRatio ?? 1
                                                                                                    }
                                                                                                },
                                                                                                iframe: {
                                                                                                    className: `${iframeCurrentAspectRatio < 1 ? 'w-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}`,
                                                                                                    style:{
                                                                                                        aspectRatio: iframeCurrentAspectRatio ?? 1
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <div className="social-media-editor-preview-post-details w-100 d-flex flex-column align-items-center justify-content-center nw-responsive-font-size-sm">
                                                                                        <span className="social-media-name">{ socialMedia?.name }</span>
                                                                                        <span className="post-type-name-and-size">{ postType?.name } ({size?.width + ' x ' + size?.height})</span>
                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                        </Row>
                                                    </Tab.Pane>
                                                )
                                            })
                                        }
                                    </Tab.Content>
                                </Tab.Container>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default SocialMediaEditor;
