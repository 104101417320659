import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Button, ListGroup } from "react-bootstrap";
import { ChevronDoubleRight, BoxArrowRight } from "react-bootstrap-icons";

import NotificationWithPrint from "../components/Notification/NotificationWithPrint";
import NotificationBox from "../components/Notification/Notifications/NotificationBox/NotificationBox";

import { 
	getBackendApiUrl, 
	getEasyPlvApiToken, 
	getAllRoutes, 
	getUserDropdownItems, 
	getAuthTokenWithRefresh,
	getLanguageOptions, 
	formatSelectedOptionUsingCurrentLang,
	getAppDocumentTitleTemplate
} from "../config";
import { getPageTitleFromRoute, getPriorityLocale, findParentRoute } from "../helpers/helpers";
import { authenticateAndConnect } from "../services/print";

import SidebarItem from "../components/Sidebar/SidebarItem";
import SidebarHeader from "../components/Sidebar/SidebarHeader";
import SidebarFocusBlock from "./SidebarFocusBlock";
import MainLogo from "../components/_utilityComponents/MainLogo";
import NwUserProfile from "../components/_utilityComponents/NwUserProfile/NwUserProfile";

import Footer from "../components/_utilityComponents/Footer";
import { checkApiResponse, UpdateUserFrontSession } from "../services/auth";

//NwHooks
import { useCurrentPlatform, useTouchDevice } from "../hooks/hooks";

//NwIcons
import { 
    NwCartIcon,
} from "../components/_utilityComponents/NwIcons/NwIcons";

//NwCustomUI
import NwCustomDropdown from "../components/_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown";
import NwBadgeWithCount from "../components/_utilityComponents/NwBadgeWithCount/NwBadgeWithCount";
import NwImage from "../components/_utilityComponents/NwImage/NwImage";

// Contexts
import MainLayoutContext from "../contexts/MainLayoutContext";

//Language Switcher
import NwLangSwitcher from "../components/_utilityComponents/NwLangSwitcher/NwLangSwitcher";
import MainLogoWithText from "../components/_utilityComponents/MainLogoWithText";

import {Toaster} from "sonner";
import Icognito from "../components/Icons/Icognito";

import useFocusBlockData from "../hooks/useFocusBlockData";

const MainLayout = ({ children }) => {
    const { 
        isAuthenticated,
        username, 
        userNw, 
        logoutUser, 
        isImpersonator,
        subscriptionData
    } = useAuth();
    const { i18n, t } = useTranslation();
    let nwRoutes = getAllRoutes();
    const dashboardRoute = nwRoutes.shift();
    
    // Handle Certain Routes
    const location = useLocation();

    //Platform specific vars
    const currentPlatform = useCurrentPlatform();
    const isTouchScreen = useTouchDevice();
    const isMobile = currentPlatform === "is-mobile";

    const [currentLang, setCurrentLang] = useState(getPriorityLocale(userNw?.locale, i18n.language));
	const [selectedLangOption, setSelectedLangOption] = useState(formatSelectedOptionUsingCurrentLang(getPriorityLocale(userNw?.locale, i18n.language)));
    const [collapsedSideBar, setCollapsedSideBar] = useState(false);
    const [ hideSideBarAndHeader, setHideSideBarAndHeader ] = useState(false);
    const [ pageTitle, setPageTitle ] = useState('Lafabric');

	/**
	 * The Additional Page Content which is injected into the page title when needed
	 * @type {[PageTitleAdditionalContent, React.Dispatch<PageTitleAdditionalContent>]}
	 */
    const [ pageTitleAdditionalContent, setPageTitleAdditionalContent] = useState(null);

	/**
	 * The Document that can be dynamically set 
	 * @type {[string|undefined|null, React.Dispatch<string|undefined|null>]}
	 */
	const [nwDocumentTitle, setNwDocumentTitle] = useState(null)
    const [hasScrolled, setHasScrolled] = useState(false);
    const [focusBlockData, setFocusBlockData] = useFocusBlockData();
    const [ navbarCompanyLogo, setNavbarCompanyLogo ] = useState('');
	const [ additionalContentData,  setAdditionalContentData] = useState(null); // used to set additional data to 'nw-content' element
    const [showFooter, setShowFooter] = useState(true);

    // Update the document title based on nwDocumentTitle
    useEffect(() => {
        const template = getAppDocumentTitleTemplate();

        // Function to generate the final document title
        const updateDocumentTitle = (title) => {
            if (title) {
                // Replace '%s' with nwDocumentTitle
                document.title = template?.replace('%s', title);
            } else {
                // If title is empty or null, just use the app name without the hyphen
                document.title = template?.replace('%s - ', '');
            }
        };

        // Call the update function with the current nwDocumentTitle
		if (nwDocumentTitle){
			updateDocumentTitle(nwDocumentTitle);
		}       
    }, [nwDocumentTitle]);

    //Handle Scrolling
    useEffect(() => {
        const handleScroll = () => {
          // Set state based on scroll position
          setHasScrolled(window.scrollY > 0);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Reset Additional Content
        setPageTitleAdditionalContent({});
        const pageTitleAndTranslationKeyArray = getPageTitleFromRoute(location.pathname);
        const currentRoute = pageTitleAndTranslationKeyArray[3];
        let translatedScreenTitle = "";
        const translatedPageTitle = t(pageTitleAndTranslationKeyArray[1]);   
        if (pageTitleAndTranslationKeyArray.length >= 3) {    
            translatedScreenTitle = t(pageTitleAndTranslationKeyArray[2]);
        }
        if (translatedScreenTitle == null)
        {
            setPageTitle(pageTitleAndTranslationKeyArray[0]);
			setNwDocumentTitle(pageTitleAndTranslationKeyArray[0])
        }
        else 
        {
            if (translatedScreenTitle) {
                setPageTitle(translatedScreenTitle);
				setNwDocumentTitle(translatedScreenTitle);
            } else {
                setPageTitle(translatedPageTitle);
				setNwDocumentTitle(translatedPageTitle);
            }
        }
        

        switch (location.pathname) {
            case '/login':
                setHideSideBarAndHeader(true);
                setShowFooter(false);
				setAdditionalContentData({
					props: {
						className: 'nw-bg-accent-3'
					}
				});
                break;
            default:
                setHideSideBarAndHeader(false);                
				setAdditionalContentData(null);
                const currentParentRoute = findParentRoute(getAllRoutes(), currentRoute?.id);
                if (currentRoute?.subMenu && currentRoute?.subMenu?.length > 0){
                    setFocusBlockData({
                        blockIdentifier: currentRoute?.id,
                        title: currentRoute?.title,
                        icon: currentRoute?.icon,
                        menuItems: currentRoute?.subMenu
                    })
                } else {
                    if (currentParentRoute?.subMenu && currentParentRoute?.subMenu?.length > 0 && !currentParentRoute.isHeader){
                        setFocusBlockData({
                            blockIdentifier: currentParentRoute?.id,
                            title: currentParentRoute?.title,
                            icon: currentParentRoute?.icon,
                            menuItems: currentParentRoute?.subMenu
                        })
                    }
                }
                break;
        }    
    }, [location]);

    if (isAuthenticated && subscriptionData?.subscriptionRights?.allowPrint) {
        authenticateAndConnect();
    }

	//// LANG SWITCHER OPTIONS
    const changeLanguage = (selectedOption) => {
        let dataSession = {
            action: 'changeLang',
            locale: selectedOption.value,
            impersonateSession : localStorage.getItem('impersonateSession')
        }
        setSelectedLangOption(selectedOption);
        UpdateUserFrontSession(dataSession);
    };

	// CHEKCK IF LANGUAGE WAS SWITCHED OR NOT
	useEffect(() => {
		if (selectedLangOption)
		{
			const selectedLanguage = selectedLangOption.value;
			setCurrentLang(selectedLanguage);
			i18n.changeLanguage(selectedLanguage);
			localStorage.setItem("lang", selectedLanguage); // Store selected language in localStorage
		}
	}, [selectedLangOption]);

	// HANDLING WHICH LANGUAGE TO TAKE PRECEDENCE( userData, or localStorage data)
    useEffect(() => {
        if (userNw)
        {
            if (userNw?.locale == i18n.language) {
                localStorage.setItem("wasLangSwitched", false); // Store selected language in localStorage
            } else {
                localStorage.setItem("wasLangSwitched", true); // Store selected language in localStorage
            }
        }
           
    }, [userNw])

	// TRIGGERS THE COLLAPSE OF THE SIDE BAR
    const handleCollapseButton = () => {
        setCollapsedSideBar(prevState => !prevState);
    }

    // HANDLE SIDEBAR ITEM ON CLICK
    const handleSideItemClick = ({
        itemIdentifier,
        itemTitle,
        itemIcon,
        menuItems,
        isFocusBlockItem = false
    }) => {

        if (isMobile && !collapsedSideBar) {
            handleCollapseButton();
        }

        if (menuItems && menuItems?.length > 0){
            // setFocusBlockData({
            //     title: itemTitle,
            //     icon: itemIcon,
            //     menuItems: menuItems,
            //     blockIdentifier: itemIdentifier
            // });
        } else {
            if (!isFocusBlockItem)
            {
                setFocusBlockData(null);
            }            
        }
    }

    const [cartCount, setCartCount] = useState(
        localStorage.getItem("updatedCartItemsCount")
    );

    const apiKey = getEasyPlvApiToken();

    const [downloadCount,setDownloadCount] = useState(0);

    const fetchDownloadCount = async () => {
        const authToken = await getAuthTokenWithRefresh();
        const res = await fetch(
        getBackendApiUrl(
            `printOrderItemDownload`
        ),
        {
            method: "GET",
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${authToken}`,
                "X-EasyPLV-Api-Token": apiKey,
            },
        }
        );

        checkApiResponse(res);
        const data = await res.json();
        if(data){
            setDownloadCount(data.itemsCount);
        }else{
            setDownloadCount('?');
        }
    };

    useEffect(() => {
        if (isAuthenticated)
        {
            fetchDownloadCount();
        }
        
        const handleStorage = () => {
            setCartCount(parseInt(localStorage.getItem("updatedCartItemsCount"), 10));
        };
        window.addEventListener("storage", handleStorage);
        return () => window.removeEventListener("storage", handleStorage);
    }, [isAuthenticated]);


	////// MANAGING BADGE VALUES //////
	// Function to determine badge value
	const getBadgeValue = (path) => {
		switch (path) {
			case '/downloads':
				return downloadCount; // Assuming downloadCount is a state
			case '/cart':
				return cartCount == "undefined" ? 0 : cartCount;
			default:
				return null; // or some default value
		}
	}
	//////////////////////////////////

    var opeDateTitle = document.getElementById('opeDate');
	if(opeDateTitle){
		opeDateTitle.textContent = "";
	}

    return (
        <div className={`${collapsedSideBar ? 'side-bar-collapsed' : ''} ${hideSideBarAndHeader ? 'no-sidebar' : ''}`}>
            { !hideSideBarAndHeader &&
                <nav className={`nw-sidebar-container overflow-y-auto hide-scrollbar shadow`}>
                    <div className="nw-sidebar pb-4">
                        {/* SIDE BAR TOP PART */}
                        <div className="nw-sidebar-top d-flex flex-column align-items-center w-100">
                            <div className={`logo-and-title-container ${collapsedSideBar ? 'is-collapsed' : ''} text-white d-flex w-100 p-2 column-gap-2`}>
                                <div className="logo-container">
                                    <Link className={`navbar-brand d-flex h-100 w-100 ${collapsedSideBar ? 'justify-content-center' : ''} column-gap-2`} to="/">
                                        <div className={`main-logo-wrapper d-flex ${collapsedSideBar ? 'justify-content-center is-collapsed' : 'justify-content-start ps-3'} align-items-center`}>
                                            { collapsedSideBar ? (
                                                <MainLogo size={30}/> 
                                            ) : (
                                                <MainLogoWithText size={'55%'}/> 
                                            ) }
                                            
                                        </div>
                                    </Link>
                                    {!collapsedSideBar && isMobile && (
                                        <div className="collapse-button-container d-flex justify-content-end align-items-center">
                                            <Button className={`sidebar-collapse-button bg-transparent border-0 w-100 text-end p-0`} onClick={() => {handleCollapseButton()}}>
                                                <ChevronDoubleRight className={`sidebar-collapse-button-icon ${collapsedSideBar ? '' : 'rotated'}`} size={20}/>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <ListGroup variant="flush" className={`nw-sidebar-items-container hide-scrollbar w-100 h-100 d-flex flex-column ${ focusBlockData ? 'justify-content-between' : '' } `}>
                                <div className="nw-sidebar-items-container-top">
                                    <SidebarItem
                                        onItemClick={handleSideItemClick}
                                        collapsedSideBar={collapsedSideBar} 
                                        itemIcon={React.cloneElement(dashboardRoute.icon, { size: '100%', className: 'sidebar-item-icon' })} 
                                        itemTitle={t(dashboardRoute.translationKey)} 
                                        linkTo={dashboardRoute.path}
                                        badgeValue={getBadgeValue(dashboardRoute.path)}
                                        currentLocation={location.pathname}
                                    />

                                    {/* HANDLE TOP MENU BLOCK HERE */}
                                    { focusBlockData && (
                                        <SidebarFocusBlock
                                            {...focusBlockData}
                                            getBadgeValue={getBadgeValue}
                                            onItemClick={handleSideItemClick}
                                            collapsedSideBar={collapsedSideBar}
                                            translationFunction={t}
                                            location={location}
                                        />
                                    )}
                                </div>
                                <div className="nw-sidebar-items-container-bottom">
                                {/* -------------------------- */}
                                    {nwRoutes.map((route, index) => {
                                        // Check the conditions based on isAuthenticated status
                                        if (route.isSidebarItem && ((isAuthenticated && route.isProtected) || (!isAuthenticated && !route.isProtected))) {
                                            let sidebarElem = route.isHeader ? (
                                                <SidebarHeader
                                                    key={index}
                                                    collapsedSideBar={collapsedSideBar}
                                                    headerTitle={t(route.translationKey)} 
                                                    sidebarMenuItems={route.subMenu}
                                                    translationFunction={t}
                                                    location={location}
                                                    getBadgeValue={getBadgeValue}
                                                    handleSideItemClick={handleSideItemClick}
                                                    headerIndex={index}
                                                    currentFocusBlock={focusBlockData}
                                                    additionalHeaderData={route.additionalHeaderData}
                                                />
                                            ) : (
                                                <SidebarItem
                                                    key={index}
                                                    id={route?.id}
                                                    onItemClick={handleSideItemClick}
                                                    collapsedSideBar={collapsedSideBar} 
                                                    itemIcon={React.cloneElement(route.icon, { size: '100%', className: `${route.icon.props.className ?? ''} sidebar-item-icon` })} 
                                                    itemTitle={t(route.translationKey)} 
                                                    linkTo={route.path}
                                                    badgeValue={getBadgeValue(route.path)}
                                                    currentLocation={location.pathname}
                                                    sidebarItemIndex={index}
                                                    additionalData={route.additionalData}
                                                />
                                            )
                                            return sidebarElem;
                                        }
                                        return null;
                                    })}
                                </div>
                            </ListGroup>
                        </div>
                        
                        {/* SIDE BAR BOTTOM PART */}
                        <div className={`nw-sidebar-bottom p-2 d-flex justify-content-around ${collapsedSideBar ? 'flex-column' : ''}`}>
                            <NwLangSwitcher
                                languageOptions={getLanguageOptions()}
                                onChange={changeLanguage}
                                value={selectedLangOption}
                                collapsedSideBar={collapsedSideBar}
                            />
                            {isAuthenticated ? (
                                <Button
                                    onClick={logoutUser}
                                    type="button"
                                    className={`w-100 d-flex justify-content-center px-0 ps-1 bg-transparent border-0 ${collapsedSideBar ? 'text-dark' : 'text-dark'}`}
                                >
                                    <BoxArrowRight size={25}/>
                                </Button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </nav>
            }
            <main id="main">
                { !hideSideBarAndHeader &&
                    <nav className={`navbar navbar-expand-lg bg-body-tertiary nw-main-navbar p-0 ${hasScrolled ? 'shadow' : 'shadow-sm'}`}>
                        <div className="d-flex justify-content-between w-100 h-100">
                            <div className={`navbar-brand p-0 d-flex align-items-center ms-3 ${isMobile ? 'w-50' : 'w-100'}`}>
                                <div className={`nw-page-title-wrapper nw-responsive-font-size-lg ${pageTitleAdditionalContent?.classes?.pageTitleWrapper ?? ''}`}>
                                    <div className="collapse-button-container collapse-button-container-navbar d-flex justify-content-end align-items-center">
                                        <Button className={`sidebar-collapse-button d-flex align-items-center bg-transparent border-0 w-100 text-end p-0`} onClick={() => {handleCollapseButton()}}>
                                            <ChevronDoubleRight className={`sidebar-collapse-button-icon ${collapsedSideBar ? '' : 'rotated'}`}/>
                                        </Button>
                                    </div>
                                    <div className={`title-and-additional-content-wrapper ${pageTitleAdditionalContent?.classes?.additionalContentWrapper ?? ''}`}>
                                        <Link to={'/'} className={`nav-link page-title`}>{pageTitle}</Link>
                                        {pageTitleAdditionalContent && pageTitleAdditionalContent?.content && (
                                            <>
                                                {pageTitleAdditionalContent.content}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="company-logo-container w-100 h-100 d-flex justify-content-center ">
                                <div className="company-logo-wrapper w-100 d-flex justify-content-center align-items-center">
                                    <NwImage imgClassName=" object-fit-contain w-100 h-100" src={navbarCompanyLogo} alt="" />
                                </div>
                            </div>
                            <div className={`d-flex justify-content-end nw-main-navbar-menu ${isMobile ? 'w-50 me-3' : 'me-4 w-100'}`}>
                                {
                                    isAuthenticated ? 
                                        isImpersonator ? <div class="bg-danger text-light rounded-1 ps-1 pe-2"> <Icognito /><Button variant="secondary" onClick={logoutUser}>{t("logout")}</Button></div> : <></>
                                     : <></>
                                }
                                {isAuthenticated ? (
                                    <div className={`nav-link nw-main-navbar-menu-item ${isMobile ? 'mx-1' : 'mx-2'} d-flex align-items-center`}>
                                        <NwUserProfile username={username} />
                                        <NwCustomDropdown dropdownItems={getUserDropdownItems(t, logoutUser)} hideDropdownIcon={isMobile}>
                                            {isMobile ? '' : (`${t('greetings')}, ${username}`)}
                                        </NwCustomDropdown>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {isAuthenticated ? (
                                    <>
                                        <Link
                                            to={'/cart'}
                                            className={`nav-link border-0 nw-main-navbar-menu-item position-relative d-flex align-items-center ${isMobile ? 'mx-1' : 'mx-3'} p-2`}
                                        >
                                            <NwCartIcon size={isMobile ? 20 : 24}/>
                                            <NwBadgeWithCount
                                                bg="accent-1"
                                                countValue={getBadgeValue('/cart')}
                                            />
                                        </Link>
                                        <NotificationBox isMobile={isMobile}/>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div>
                                {isAuthenticated ? <NotificationWithPrint /> : <></>}
                            </div>
                        </div>
                    </nav>
                }

                <MainLayoutContext.Provider value={{ 
                    setPageTitle, 
                    setPageTitleAdditionalContent,
					setNwDocumentTitle,
                    setShowFooter,
                    currentPlatform, 
                    isTouchScreen,
					setFocusBlockData,
                    locale: currentLang,                    
                }}>
                    <div key={location.pathname} className={`nw-content ${ additionalContentData?.props?.className ?? '' } ${ currentPlatform } ${ !isMobile ? 'show-fade-in-animation' : ''}`}>
                        {children}
                    </div>
                </MainLayoutContext.Provider>
                
                {showFooter &&
                    <div className="px-4 py-2">
                        <Footer/>
                    </div>
                }
                
            </main>

            <Toaster richColors />
        </div>
    );
};

export const useLayoutContext = () => {
    return useContext(MainLayoutContext);
};

export default MainLayout;
