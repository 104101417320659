import { getBackendUrl } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import { getProductById } from "../../services/posters";
import { useEffect, useState, useContext, useRef, memo } from "react";

// Poster Services
import { getDefaultPosterData } from "../../services/posters";
import { formatLabelforFormatPreview } from "../../helpers/helpers";

// Nw logos
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";

// Contexts
import MainLayoutContext from "../../contexts/MainLayoutContext";
import EditorIframePreview from "../Editor/EditorIframePreview";

const Poster = memo((props) => {
	const { userNw } = useAuth();
	const { currentPlatform } = useContext(MainLayoutContext);
    const iframeRef = useRef(null);
	
	const [originalShowHeaderName, setOriginalShowHeaderName] = useState(false);
	const [originalShowHeaderDate, setOriginalShowHeaderDate] = useState(false);
	const [selectedFormatOption, setSelectedFormatOption] = useState(null);
	const [selectedOrientationOption, setSelectedOrientationOption] = useState(null);
	const [ iframeIsLoading, SetIframeIsLoading ] = useState(true); // used for the iframe itself to load
    const [ iframeCurrentAspectRatio, setIframeAspectRatio ] = useState(null);
	const [ isInitialLoad, setIsInitialLoad ] = useState(true); //Used when All data have been fetched initially

    // const posterId = queryParams.get("poster_id");
    const posterId = props.posterId;
    // const formatId = queryParams.get("format");
    const formatId = props.formatId;
    
    // const orientationQuery = queryParams.get("orientation");
    const orientationQuery = props.orientationQuery;
	// let showImageProductQuery = queryParams.get("showImageProduct");
    let showImageProductQuery = props.showImageProduct;
    if (showImageProductQuery === null || showImageProductQuery === undefined) {
        showImageProductQuery = getDefaultPosterData().showImageProduct;
    }

	useEffect(() => {
		getProductById(posterId)
			.then((data) => {
				// SET ORIENTATION AND FORMAT INTO FORM ACCORDINGLY
				const FormatOptions = data.selectedTemplateGroupData?.metadata?.format?.select;
				const OrientationOptions = data.selectedTemplateGroupData?.metadata?.orientation?.byFormatselect[formatId];

				if (FormatOptions) {
					setSelectedFormatOption(FormatOptions.find(format => format.value == formatId));
				}
				if (OrientationOptions) {
					setSelectedOrientationOption(OrientationOptions.find(orientation => orientation.value == orientationQuery));
				}

				if (data.customPosterData) {
					setOriginalShowHeaderName(data.customPosterData?.showHeaderName);
					setOriginalShowHeaderDate(data.customPosterData?.showHeaderDate);
				} else {
					const selectedTemplateGroupData = data?.selectedTemplateGroupData;
					if (selectedTemplateGroupData) {
						const rendererLayoutConfig = selectedTemplateGroupData?.rendererLayoutConfiguration;
						if (rendererLayoutConfig) {
							setOriginalShowHeaderDate({type: 'date', value: rendererLayoutConfig.showDateHeader});
							setOriginalShowHeaderName({type: 'name', value: rendererLayoutConfig.showNameHeader});
						}
					}
				} 
			});
	}, [formatId, orientationQuery]);

	useEffect(() => {
        if (userNw && posterId && selectedFormatOption && selectedOrientationOption && originalShowHeaderDate && originalShowHeaderName)
        {
            
            setIsInitialLoad(false);
        }
	}, [userNw, posterId, selectedFormatOption, selectedOrientationOption, originalShowHeaderDate, originalShowHeaderName])

	useEffect(() => {
        // HANDLE IFRAME ON FIRST LOAD
        // Check if the iframe is currently available
        if (iframeRef?.current) {
            const iframeElement = iframeRef.current;
        
            // Define a function that will be called when the iframe finishes loading
            const iframeLoadHandler = () => {
                SetIframeIsLoading(false);
            };
            // Add the event listener for the 'load' event
            iframeElement.addEventListener('load', iframeLoadHandler);
            // Cleanup: remove the event listener when the component unmounts
            return () => {
                iframeElement.removeEventListener('load', iframeLoadHandler);
            };
        }
    }, [userNw, isInitialLoad]);

	useEffect(() => {
        SetIframeIsLoading(true);
        
        if (!isInitialLoad){
            setIframeAspectRatio(handleFormatPreviewOrientation().aspectRatio);
        }
    }, [selectedOrientationOption, isInitialLoad]);

	//Handle Formatting for Format Preview, also used to assign aspectRatio to iframe element
	const handleFormatPreviewOrientation = () => {
        if (selectedFormatOption && selectedOrientationOption){
            return formatLabelforFormatPreview(selectedOrientationOption.value, selectedFormatOption.size);
        }
    }

    return (
    
		<div className="nw-poster-body d-flex w-100 h-100">
            {showImageProductQuery}
				<div className='w-100 position-relative h-100'>
                    <div className="preview-container-wrapper w-100 h-100 bg-transparent">
                        <div className="preview-container w-100 h-100 d-flex justify-content-center px-5"
                            style={{
                                paddingBlock: currentPlatform === "is-large-desktop-3xxl" ? '2%' : '1%'
                            }}
                        >
                            <div className="preview-nw d-flex justify-content-center" style={{ height: '100%', width: iframeCurrentAspectRatio == 1 ? '70%' : iframeCurrentAspectRatio < 1 ? '33%' : '85%' }}>
                                {iframeIsLoading ? (
                                    <div className={`preview-nw-spinner ${isInitialLoad ? 'initial-loading' : 'text-white'} ${selectedOrientationOption?.value}  position-absolute text-secondary`} role="status">
                                        <NwLoaderSpinner isSpinner size={40}/>
                                    </div>) :
                                    (null)
                                }
                                {userNw && !isInitialLoad && 
                                    (
										<div 
											className={`preview_iframe_wrapper ${iframeCurrentAspectRatio < 1 ? 'h-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}  d-block align-self-center ${iframeIsLoading ? 'iframe-is-loading' : 'iframe-is-loaded'}`} 
											style={{aspectRatio: iframeCurrentAspectRatio ?? 1}} 
										>
											<iframe 
												ref={iframeRef} 
												id='product_iframe' 
												className={`preview_iframe ${iframeCurrentAspectRatio < 1 ? 'h-100': 'w-100'} ${iframeCurrentAspectRatio == 1 ? 'w-100': ''}  d-block align-self-center ${iframeIsLoading ? 'd-none' : ''}`} 
												style={{aspectRatio: iframeCurrentAspectRatio ?? 1}} 
												src={getBackendUrl(`rendererFrontReact/${userNw.id}/${posterId}/${selectedFormatOption?.value}/${selectedOrientationOption?.value}?hideProductImage=${showImageProductQuery === false ? 1 : 0}&showHeaderName=${originalShowHeaderName?.value ? 1 : 0}&showHeaderDate=${originalShowHeaderDate?.value ? 1 : 0}&debugMode=0`)} 
											></iframe>
										</div>                               
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
		</div>
    )
});

export default Poster;
